import App from "App"
import PropsProvider from "contexts/PropsContext"
import React from "react"
import { CookiesProvider } from "react-cookie"
import ReactDOM from "react-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import reportWebVitals from "reportWebVitals"

import "assets/styles/global.css"
import "assets/styles/index.css"
import "assets/styles/player.css"

const main = async () => {
  ReactDOM.render(
    <React.StrictMode>
      <PropsProvider>
        <CookiesProvider>
          <App />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </CookiesProvider>
      </PropsProvider>
    </React.StrictMode>,
    document.getElementById("app")
  )
}

reportWebVitals()
main()
