import { PerkInterface } from "libs/interfaces"
import React from "react"
import { MdCheck } from "react-icons/md"

const EvolutionPerksColumn = ({ title, perks }: { title: string; perks: PerkInterface[] }) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-24 sm:gap-32">
        <div className="w-full text-24 sm:text-32 xl:text-48 text-white font-bold">{title}</div>
        <div className="w-full grid grid-cols-1 gap-24">
          {perks.map((entry: PerkInterface, key: number) => (
            <div className="w-full grid grid-cols-[auto,1fr] gap-16" key={key}>
              <div className="w-32 h-32 border-1 border-green-50 rounded-full bg-green-25 flex items-center justify-center">
                <MdCheck className="text-18 text-green" />
              </div>
              <div className={`w-full text-16 sm:text-18 xl:text-24 ${entry.special ? "text-white" : "text-grey"}`}>
                {entry.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default EvolutionPerksColumn
