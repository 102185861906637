import { URL } from "libs/constants"
import React from "react"
import { Link } from "react-router-dom"
import Button from "utils/Button"

const AboutTop = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1">
        <div className="w-full relative">
          <div className="absolute transform translate-x-[50%] translate-y-[-50%] top-60 right-60 w-[1200px] h-[1200px] rounded-full opacity-[0.04] bg-gradient-to-r from-white to-transparent rotate-[13deg]"></div>
        </div>
        <div className="w-full flex justify-center">
          <div className="max-w-full w-[700px]">
            <div className="w-full text-center mb-18 sm:mb-24 text-18 sm:text-24 md:text-36 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
              #HACKYOURFUTURE
            </div>
            <div className="w-full text-center xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
              OVER UNIVERSALES
            </div>
            <div className="w-full flex">
              <div className="text-left w-full mb-48 text-grey text-14 md:text-18 font-semibold">
                De arbeidsmarkt is aan het veranderen. Door de toenemende digitalisering verdwijnen sommige banen,
                andere worden juist gecreëerd. Dat betekent dat je enerzijds soms op zoek moet naar ander werk,
                anderzijds betekent het dat je in jezelf moet investeren. Menselijke eigenschappen zoals creativiteit,
                improvisatie- en inlevingsvermogen zullen altijd belangrijk blijven in ons werk.
                <br />
                <br />
                UNIVERSALES is het werkplatform van de toekomst. Wij vinden een match tussen jou en één van de
                opdrachtgevers die je een toekomstbestendige baan kan bieden. Je leert voor 70% door te doen, 20% door
                coaching en mentoring en 10% met trainingen. Uiteindelijk ben jij degene die bepaalt waar, voor wie en
                hoeveel je werkt!
              </div>
            </div>
            <div className="hidden w-full flex-wrap items-center justify-center gap-8">
              <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                <Button title={"Account maken"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutTop
