import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { IoMdDownload } from "react-icons/io";

const Dropzone = ({ files, handler }: { files?: File[]; handler: any }) => {
  const onDrop = useCallback((files: File[]) => {
    handler(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
  });

  return (
    <>
      <div className="w-full relative">
        <div className="w-full" {...getRootProps()}>
          <input {...getInputProps()} />

          <div
            className={`border-1 bg-grey-border bg-opacity-20 rounded-6 p-20 grid grid-cols-[auto,1fr] gap-20 items-center cursor-pointer border-dashed ${
              isDragActive ? "border-white" : "border-grey-border"
            }`}
          >
            <IoMdDownload className="text-grey text-18" />
            <div className="w-full grid grid-cols-1 gap-4">
              <div className="text-white text-16 font-semibold">
                Drag files here or <span className="text-blue">browse</span>
              </div>
              {files ? (
                <div className="w-full text-grey text-16 font-semibold">
                  {files.length} file{files.length >= 2 ? "s" : ""} selected
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropzone;
