import SlideEva from "assets/img/evolution/slide-eva.png"
import SlideSanta from "assets/img/evolution/slide-santa.png"
import { SlideEvolutionInterface } from "libs/interfaces"
import React, { useEffect, useRef, useState } from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { useSwipeable } from "react-swipeable"
import Wrapper from "utils/Wrapper"

const EvolutionSlides = () => {
  const wrapper = useRef<HTMLDivElement>(null)
  const item = useRef<HTMLDivElement>(null)

  const [step, setStep] = useState(0)

  const [widthItem, setWidthItem] = useState(890)
  const [widthWrapper, setWidthWrapper] = useState(890)

  const [roadmap] = useState<SlideEvolutionInterface[]>([
    {
      title: "“Dit is het leukste wat ik in tijden heb meegemaakt!”",
      text: "Het is al een geweldige baan, maar de locatie heeft mij echt overtuigd. Het is zo fijn om met mensen van over de hele wereld te werken, allemaal op één plek.",
      role: "Eva, Spelpresentator",
      image: SlideEva,
    },
    {
      title: "“Bij Evolution krijg je echt de kans om je te ontwikkelen”",
      text: "Ik kwam bij EVO als een Game Presenter. 10 jaar later heb ik in vier verschillende landen gewerkt en nu heb ik de kans om onze studio in Vancouver te leiden. Het is een fantastische reis geweest en ik heb zo veel geleerd, zowel professioneel als persoonlijk.",
      role: "Santa, Hoofd van studio",
      image: SlideSanta,
    },
  ])

  useEffect(() => {
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  useEffect(() => {
    if (item && item.current) {
      setWidthItem(item.current.clientWidth)
    }
  }, [item])

  useEffect(() => {
    if (wrapper && wrapper.current) {
      setWidthWrapper(wrapper.current.clientWidth)
    }
  }, [wrapper])

  const swipeable = useSwipeable({
    onSwipedLeft: () => {
      onStep(1)
    },
    onSwipedRight: () => {
      onStep(-1)
    },
  })

  const onResize = () => {
    if (item && item.current) {
      setWidthItem(item.current.clientWidth)
    }

    if (wrapper && wrapper.current) {
      setWidthWrapper(wrapper.current.clientWidth)
    }
  }

  const onStep = (offset: number) => {
    if (step + offset < 0) {
      setStep(roadmap.length - 1)
    } else if (step + offset >= roadmap.length) {
      setStep(0)
    } else {
      setStep(step + offset)
    }
  }

  return (
    <>
      <div {...swipeable} className="relative z-10 w-full">
        <div className="relative grid w-full grid-cols-1 gap-100">
          <div className="grid w-full grid-cols-1 gap-40 xl:gap-60 xl:grid-cols-[1fr,auto]">
            <div className="flex w-full justify-start">
              <div className="w-[700px] max-w-full relative">
                <div className="w-full xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
                  Wie is <br />
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue text-32 sm:text-48 md:text-72">
                    Evolution Gaming
                  </span>
                  ?
                </div>
                <div className="w-full flex">
                  <div className="w-[500px] max-w-full mb-48 text-grey text-14 md:text-18 font-semibold">
                    Evolution is een Zweeds bedrijf met meer dan 12.000 medewerkers op meer dan 20 locaties die van over
                    de hele wereld komen. Het bedrijf is een grote leverancier van software voor online casino’s. Vanuit
                    onze studio’s zenden we wereldwijd uit in meer dan 15 verschillende talen voor verschillende
                    klanten. Dit maakt het leuk voor iedereen die houdt van een internationale sfeer, met de kans om ook
                    in het Nederlands te praten tegen collega’s!
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-30">
              <button
                onClick={() => onStep(-1)}
                className="relative flex h-70 w-70 items-center justify-center border-1 border-white rounded-full hover:bg-white group"
              >
                <MdKeyboardArrowLeft className="relative text-24 text-white group-hover:text-blue" />
              </button>
              <button
                onClick={() => onStep(1)}
                className="relative flex h-70 w-70 items-center justify-center border-1 border-white rounded-full hover:bg-white group"
              >
                <MdKeyboardArrowRight className="relative text-24 text-white group-hover:text-blue" />
              </button>
            </div>
          </div>
          <div className="flex w-full justify-center">
            <div className="relative w-full">
              <div
                ref={wrapper}
                style={{ transform: `translateX(${-step * widthItem}px)` }}
                className="relative w-full transition-all duration-200"
              >
                <div className="flex w-max">
                  {roadmap.map((entry: SlideEvolutionInterface, key: number) => (
                    <div
                      style={{ width: `${Math.min(widthWrapper, 890)}px` }}
                      className="w-full pr-20 sm:pr-40 md:pr-60 relative"
                      key={key}
                    >
                      <Wrapper open={key === 0}>
                        <div ref={item} className="absolute top-0 left-0 w-full"></div>
                      </Wrapper>
                      <div className="relative rounded-16 h-full bg-gradient-to-r from-purple to-blue p-1">
                        <div className="w-full bg-background rounded-[15px] overflow-hidden h-full">
                          <div className="w-full gap-20 sm:gap-40 md:gap-60 p-20 sm:p-40 md:p-60 bg-gradient-to-r from-purple-10 to-blue-10 h-full grid grid-cols-[auto,1fr] items-center">
                            <div className="w-80 sm:w-100 md:w-[150px]">
                              <img src={entry.image} alt="" className="rounded-full" />
                            </div>
                            <div className="w-full">
                              <div className="font-bold mb-24 w-full text-24 md:text-36 text-white">{entry.title}</div>
                              <div className="text-grey-text w-full text-14 sm:text-16 md:text-18 mb-24">
                                {entry.text}
                              </div>
                              <div className="text-grey-text font-bold w-full text-14 sm:text-16 md:text-18">
                                {entry.role}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EvolutionSlides
