import Banner from "assets/img/banner_band.png"
import AdvertisingCoffee from "components/advertising/AdvertisingCoffee"
import AdvertisingStart from "components/advertising/AdvertisingStart"
import AdvertisingTop from "components/advertising/AdvertisingTop"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import React from "react"

const Advertising = () => {
  return (
    <>
      <Nav />
      <div className="w-full flex items-center justify-center px-20 sm:px-30 relative z-20">
        <div className="w-full max-w-screen-2xl">
          <div className="w-full h-[700px] flex flex-wrap items-center mb-40 md:mb-[140px]">
            <div className="w-full relative z-10">
              <div className="absolute top-[-80px] w-max h-[780px] left-[50%] transform translate-x-[-50%]">
                <img src={Banner} alt="" className="min-h-full" />
              </div>
            </div>
            <div className="w-full z-20">
              <AdvertisingTop />
            </div>
          </div>
          <div className="w-full mb-40 md:mb-[140px]">
            <AdvertisingStart />
          </div>
          <div className="w-full mb-40 md:mb-[140px] relative">
            <AdvertisingCoffee />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Advertising
