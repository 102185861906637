import { ReactComponent as Logo } from "assets/img/logo.svg"
import ShadeTop from "assets/img/shade_top.png"
import React from "react"

const ContentTop = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 2xl:grid-cols-[1fr,360px] gap-40 md:gap-80 xl:gap-200 3xl:gap-80 relative">
        <div className="absolute top-[-600px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
        <div className="w-full flex justify-center 2xl:justify-start">
          <div className="w-[580px] max-w-full relative">
            <div className="w-full xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
              Learn. Practice.{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue text-32 sm:text-48 md:text-72">
                Create
              </span>
            </div>
            <div className="w-full flex">
              <div className="w-[500px] max-w-full mb-48 text-grey text-14 md:text-18 font-semibold">
                <span className="text-white text-14 md:text-18">UNIVERSALES</span> is het begin van jouw succesvolle
                Sales carrière. Wil je de wereld achter de schermen van een mooie actie of aanbieding leren kennen?
                Start met het verkopen van producten en diensten van Topmerken. Of help je liever een ander aan een
                droombaan? Ga dan aan de slag als Recruitment consultant. Werken in vrijheid, goede verdiensten &
                doorgroei mogelijkheden. Creëer jouw ideale toekomst!
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[260px] sm:h-[360px] 2xl:h-auto">
          <div className="w-full  mb-0 sm:mb-20 md:mb-32 flex justify-center">
            <Logo className="w-[260px] sm:w-[360px] h-[260px] sm:h-[360px]" />
          </div>
          <div className="hidden 2xl:flex w-full text-center text-48 font-bold text-white">UNIVERSALES</div>
        </div>
      </div>
    </>
  )
}

export default ContentTop
