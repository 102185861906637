import { ReactComponent as Logo } from "assets/img/logo.svg"
import { URL } from "libs/constants"
import React, { useState } from "react"
import { IoMdCheckboxOutline } from "react-icons/io"
import { Link } from "react-router-dom"
import { Link as LinkScroll } from "react-scroll"
import Button from "utils/Button"
const HomeTop = () => {
  const [stats] = useState([
    <>
      Start met het verkopen van producten en diensten van <span className="text-white">A-merken</span>.
    </>,
    <>
      Groei door in Sales en solliciteer op goed betaalde <span className="text-white">Salesfuncties</span>.
    </>,
    <>
      Maak Sales vanuit elke locatie via verschillende <span className="text-white">verkoopkanalen</span>.
    </>,
  ])

  return (
    <>
      <div className="w-full grid grid-cols-1 2xl:grid-cols-[1fr,360px] gap-40 md:gap-80 xl:gap-200 3xl:gap-80 relative items-center">
        {/* <div className="absolute transform translate-x-[50%] translate-y-[-50%] top-60 right-60 w-[1200px] h-[1200px] rounded-full opacity-[0.04] bg-gradient-to-r from-white to-transparent rotate-[13deg]"></div> */}
        <div className="w-full flex justify-center 2xl:justify-start">
          <div className="max-w-full w-[700px] 2xl:w-full relative">
            <div className="w-full mb-18 sm:mb-24 text-18 sm:text-24 md:text-36 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
              Learn. Practice. Create
            </div>
            <div className="w-full xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
              UNIVERSALES is het begin van jouw succesvolle Sales carrière.
            </div>
            <div className="w-full flex">
              <div className="w-[500px] max-w-full mb-48 grid grid-cols-1 gap-10">
                {stats.map((stat: JSX.Element, key: number) => (
                  <div className="w-full grid grid-cols-[auto,1fr] gap-12" key={key}>
                    <IoMdCheckboxOutline className="text-white text-20 mt-2" />
                    <div className="w-full text-grey text-14 md:text-18 font-semibold">{stat}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-wrap items-center gap-8">
              <LinkScroll to={"register"} offset={-100}>
                <div className="w-full md:w-[212px]">
                  <Button title={"Meld je aan"} />
                </div>
              </LinkScroll>
              <Link
                to={URL.ABOUT}
                className="w-full md:w-auto h-60 flex items-center md:px-60 text-white text-16 font-bold"
              >
                Meer informatie
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full mb-20 md:mb-32 flex justify-center">
            <Logo className="w-full" />
          </div>
          <div className="hidden 2xl:flex w-full text-center text-48 font-bold text-white">UNIVERSALES</div>
        </div>
      </div>
    </>
  )
}

export default HomeTop
