import React from "react";

const FooterText = ({ props }: { props: any }) => {
  return (
    <>
      <div className="w-full text-grey text-14 xl:text-18">{props.title}</div>
    </>
  );
};

export default FooterText;
