import { URL } from "libs/constants"
import React from "react"
import { Link } from "react-router-dom"
import Button from "utils/Button"

const AdvertisingTop = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 relative">
        <div className="absolute transform translate-x-[50%] translate-y-[-50%] top-60 right-60 w-[1200px] h-[1200px] rounded-full opacity-[0.04] bg-gradient-to-r from-white to-transparent rotate-[13deg]"></div>
        <div className="w-full flex justify-center">
          <div className="max-w-full w-[700px] relative">
            <div className="w-full text-center mb-18 sm:mb-24 text-18 sm:text-24 md:text-36 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
              We search, we match, we recruit
            </div>
            <div className="w-full text-center xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
              JONG TALENT NODIG?
            </div>
            <div className="w-full flex justify-center">
              <div className="text-left w-[600px] max-w-full mb-48 text-grey text-14 md:text-18 font-semibold">
                Maak je geen zorgen meer over die openstaande vacatures. Wij vinden het talent dat je nodig hebt. Jong,
                fris en vol energie. We nemen alles rondom werving, selectie, contracten en salaris uit handen. Laat ons
                weten wie je zoekt, dan maken wij direct een voorstel op maat. Onze flexkrachten willen graag werken en
                wij hebben een speciale hotline om je extra snel te kunnen helpen. Dus heb jij snel extra mensen nodig?
              </div>
            </div>
            <div className="w-full flex flex-wrap items-center justify-center gap-8">
              <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                <Button title={"Account maken"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvertisingTop
