import { ReactComponent as Logo } from "assets/img/logo.svg"
import ShadeBottom from "assets/img/shade_bottom.png"
import FooterLink from "components/footer/FooterLink"
import FooterText from "components/footer/FooterText"
import FooterTitle from "components/footer/FooterTitle"
import { SOCIAL, URL } from "libs/constants"
import { openLink } from "libs/functions"
import { LinkInterface, SocialInterface } from "libs/interfaces"
import React, { useState } from "react"
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { IoMdCheckboxOutline } from "react-icons/io"

const Footer = () => {
  const [home] = useState<LinkInterface[]>([
    { title: "Home", link: URL.HOME },
    { title: "Platform", link: URL.CONTENT },
    { title: "Over ons", link: URL.ABOUT },
    { title: "Adverteerders", link: URL.ADVERTISING },
    { title: "Contact", link: URL.CONTACT },
    { title: "Privacy policy", link: URL.PRIVACY },
  ])

  const [socials] = useState<SocialInterface[]>([
    { icon: <FaLinkedinIn />, link: SOCIAL.LINKEDIN },
    { icon: <FaFacebookF />, link: SOCIAL.FACEBOOK },
    { icon: <FaInstagram />, link: SOCIAL.INSTAGRAM },
  ])

  const [stats] = useState([
    <>
      Start met het verkopen van producten en diensten van <span className="text-white">A-merken</span>.
    </>,
    <>
      Groei door in Sales en solliciteer op goed betaalde <span className="text-white">Salesfuncties</span>.
    </>,
    <>
      Maak Sales vanuit elke locatie via verschillende <span className="text-white">verkoopkanalen</span>.
    </>,
  ])

  return (
    <>
      <div className="w-full p-20 md:py-30 2xl:py-[80px] flex flex-wrap justify-center px-20 sm:px-30 z-0">
        <div className="w-full relative z-0">
          <div className="hidden md:flex absolute bottom-[-500px] md:bottom-[-700px] 2xl:bottom-[-600px] left-[50%] transform translate-x-[-50%] w-max">
            <img src={ShadeBottom} alt="" className="" />
          </div>
        </div>
        <div className="w-full z-10 max-w-screen-2xl flex flex-wrap justify-between items-center">
          <div className="w-full grid grid-cols-1 xl:grid-cols-[500px,1fr] gap-40 xl:gap-80 items-start justify-between">
            <div className="w-full grid grid-cols-1 gap-30">
              <div className="w-full grid grid-cols-[auto,1fr] gap-20 items-center">
                <Logo className="w-50 h-50" />
                <div className="w-full text-white text-18 font-bold">UNIVERSALES</div>
              </div>
              <div className="w-[500px] max-w-full grid grid-cols-1 gap-10">
                {stats.map((stat: JSX.Element, key: number) => (
                  <div className="w-full grid grid-cols-[auto,1fr] gap-12" key={key}>
                    <IoMdCheckboxOutline className="text-white text-20 mt-2" />
                    <div className="w-full text-grey text-14 md:text-18 font-semibold">{stat}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className="w-full xl:w-auto max-w-full flex flex-wrap items-start gap-40 2xl:gap-80">
                <div className="grid grid-cols-1 gap-20 sm:gap-30">
                  <FooterTitle title={"Menu"} />
                  <div className="w-full grid grid-cols-1 gap-12 sm:gap-20">
                    {home.map((link: LinkInterface, key: number) => (
                      <FooterLink title={link.title} link={link.link} key={key} />
                    ))}
                    {/* <button
                      onClick={(e) => openLink(e, `/documents/Privacy_Policy_Universales.pdf`)}
                      className="w-full text-grey hover:text-white text-14 xl:text-18"
                    >
                      Privacy Policy
                    </button> */}
                  </div>
                </div>
                <div className="w-[380px] max-w-full grid grid-cols-1 gap-48">
                  <div className="grid grid-cols-1 gap-20 sm:gap-30">
                    <FooterTitle title={"Maak een afspraak op locatie"} />
                    <FooterText
                      props={{
                        title: (
                          <>
                            Ankersmidplein 2, Zaandam, 1506 CK, <br />
                            Nederland
                          </>
                        ),
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-20 sm:gap-30">
                    <FooterTitle title={"Neem contact met ons op"} />
                    <FooterText
                      props={{
                        title: (
                          <>
                            <button
                              onClick={(e) => openLink(e, "mailto:recruitment@universales.work")}
                              className="hover:text-white"
                            >
                              recruitment@universales.work
                            </button>{" "}
                            <br />
                            085 - 0607658
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-20 md:my-30 xl:my-60 border-b-1 border-grey border-opacity-10"></div>
          <div className="w-full flex flex-wrap gap-20 md:gap-30 items-center justify-between">
            <div className="text-grey text-14 md:text-18">© Copyright 2021 UNIVERSALES, Inc. All Rights Reserved.</div>
            <div className="flex items-center gap-20 sm:gap-30">
              {socials.map((social: SocialInterface, key: number) => (
                <button
                  onClick={(e) => openLink(e, social.link)}
                  className="w-50 h-50 rounded-full border-1 border-white hover:bg-white-10 flex items-center justify-center "
                  key={key}
                >
                  <span className="first:text-white">{social.icon}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
