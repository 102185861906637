import { ReactComponent as Evolution } from "assets/img/evolution.svg"
import ShadeTop from "assets/img/shade_top.png"
import React from "react"

const EvolutionTop = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 2xl:grid-cols-2 gap-40 md:gap-80 relative items-center">
        <div className="absolute top-[-600px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
        <div className="w-full flex justify-center 2xl:justify-start relative">
          <div className="w-[580px] max-w-full relative">
            <div className="w-full xl:w-[800px] 2xl:w-[900px] max-w-full mb-20 md:mb-32 text-32 sm:text-48 md:text-72 text-white font-bold leading-[110%]">
              Evolution Introduction
            </div>
            <div className="w-full flex">
              <div className="w-[500px] max-w-full mb-48 text-grey text-14 md:text-18 font-semibold">
                Wij bij <span className="text-white text-14 md:text-18">Evolution</span> zijn op zoek naar Game
                Presenters om ons Nederlands team te versterken in Malta of Madrid! Als game presentator ben je
                verantwoordelijk voor jouw eigen digitale casino, waar mensen graag de bekendste spelletjes uit Las
                Vegas proberen, maar met het comfort van thuis. Je neemt plaats in een van onze professionele studio’s,
                waar je de game opent, sluit en interactie hebt met de spelers. Voor onze Nederlandse teams zijn alle
                spellen in het Nederlands. We zenden 24/7 uit, zo kun je in de ochtend-, dag- of in de nachtploeg werken
                met je vrienden.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mb-0 sm:mb-20 md:mb-32 flex justify-center relative">
          <Evolution className="w-[500px] max-w-full" />
        </div>
      </div>
    </>
  )
}

export default EvolutionTop
