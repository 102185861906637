import React from "react"

const PrivacyBox = ({ text }: { text: JSX.Element }) => {
  return (
    <>
      <div className="w-full flex justify-center border-1 rounded-4 border-white-10 p-20">
        <div className="text-16 text-white w-full">{text}</div>
      </div>
    </>
  )
}

export default PrivacyBox
