import Promo from "assets/img/promo.jpg"
import Footer from "components/footer/Footer"
import HomeBenefits from "components/home/HomeBenefits"
import HomeCampaigns from "components/home/HomeCampaigns"
import HomeRegister from "components/home/HomeRegister"
import HomeTop from "components/home/HomeTop"
import Nav from "components/nav/Nav"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { scroller as Scroller } from "react-scroll"

const Home = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      Scroller.scrollTo(location.hash.replace("#", ""), { offset: -100 })
    }
  }, [location])

  return (
    <>
      <Nav />
      {/* <div className="w-full relative">
        <div className="hidden md:flex absolute top-[-465px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
      </div> */}
      <div className="w-full z-10 mt-80 flex items-center justify-center p-20 md:px-30 md:py-50 relative">
        <div className="w-full max-w-screen-2xl">
          <div className="w-full my-0 2xl:mt-[60px] 2xl:mb-[60px]">
            <div className="w-full mb-40 md:mb-[200px]">
              <HomeTop />
            </div>
            <div className="w-full mb-80 md:mb-200">
              <HomeBenefits />
            </div>
            <div className="w-full mb-80 md:mb-[140px]">
              <HomeRegister />
            </div>
            <div className="w-full mb-40 md:mb-[140px] flex justify-center">
              <div className="w-[900px] max-w-full">
                <img src={Promo} alt="" className=" cursor-pointer w-full rounded-20" />
              </div>
            </div>
            <div className="w-full mb-40 md:mb-[140px]">
              <HomeCampaigns />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
