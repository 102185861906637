export function openLink(event: any, link: string) {
  event.preventDefault();
  window.open(link);
}

export function parseMinutes(number: number) {
  const seconds = Math.round(number);

  return `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? "0" : ""}${seconds % 60}`;
}
