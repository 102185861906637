import React from "react"

const PrivacyWarning = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full flex justify-center">
        <div className="text-18 text-blue sm:text-20 font-bold">{title}</div>
      </div>
    </>
  )
}

export default PrivacyWarning
