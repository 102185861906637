import React from "react";

const FooterTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full text-white text-18 xl:text-24 font-bold">{title}</div>
    </>
  );
};

export default FooterTitle;
