import SalesAgent from "assets/img/content/sales_agent.png"
import SalesProfessional from "assets/img/content/sales_professional.png"
import WorkOnline from "assets/img/content/work_online.png"
import { ReactComponent as Dotted } from "assets/img/dotted.svg"
import Triangle from "assets/img/triangle.png"
import ContentSubList from "components/content/ContentSubList"
import ContentSubText from "components/content/ContentSubText"
import ContentSubTextBold from "components/content/ContentSubTextBold"
import ContentSubTitle from "components/content/ContentSubTitle"
import { URL } from "libs/constants"
import { CareerInterface } from "libs/interfaces"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import Button from "utils/Button"
import Wrapper from "utils/Wrapper"

const ContentCareer = () => {
  const [careers] = useState<CareerInterface[]>([
    {
      icon: SalesAgent,
      title: "Sales Agent",
      content: (
        <div className="w-full grid grid-cols-1 gap-20">
          <ContentSubTitle title="Werken in Sales" />
          <ContentSubText title="Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een hoge motivatie en doorzettingsvermogen. Start als Sales Agent bij een van de aangesloten verkooporganisaties en maak je eerste omzet! Hieronder lees je wat je kunt gaan doen en mag verwachten." />
          <ContentSubTitle title="De start van jouw sales carrière" />
          <ContentSubText title="Maak sales voor Media, Telecom (internet, televisie, vaste telefonie) & Mobiel (sim-only abonnementen en smartphones) of allemaal tegelijk!" />
          <ContentSubList
            list={[
              <li className="text-14 md:text-16" key={0}>
                Je gaat op actieve wijze <span className="text-white text-14 md:text-16">nieuwe klanten</span> werven,
                bellen of op locatie aanspreken. Het is jouw taak om de producten en diensten van Adverteerders op de
                best mogelijke manier te verkopen.
              </li>,
              <li className="text-14 md:text-16" key={1}>
                Daarnaast benader je <span className="text-white text-14 md:text-16">bestaande klanten</span> van
                Adverteerders. Je zorgt ervoor dat ze tevreden blijven en dat deze klanten een extra product of dienst
                afnemen van de Adverteerders.
              </li>,
            ]}
          />
        </div>
      ),
    },
    {
      icon: SalesProfessional,
      title: "Sales Professional",
      content: (
        <div className="w-full grid grid-cols-1 gap-20">
          <ContentSubTitle title="De next step van jouw Sales carrière" />
          <ContentSubTextBold title="Doorgroeimogelijkheden" />
          <ContentSubText title="Beschik je over relevante werkervaring of heb je een commerciële Hbo-opleiding succesvol afgerond; dan ben je klaar voor de volgende stap in jouw sales carrière. Maak gebruik van het UNIVERSALES netwerk en samen vinden we de opdrachtgever en salesfunctie die bij jou past." />
          <ContentSubText title="Onder salesfuncties vallen de beroepen waarin het bereiken van een commerciële overeenkomst tussen organisatie en klant het uitganspunt is. Het kan hierbij gaan om de verkoop van fysieke producten, maar ook om bijvoorbeeld een contract voor een bepaalde dienst. Ook de beroepen die zich richten op relatiebeheer, accountmanagement en business development vallen binnen de functiegroep sales." />
        </div>
      ),
    },
    {
      icon: WorkOnline,
      title: "Work Online",
      content: (
        <div className="w-full grid grid-cols-1 gap-20">
          <ContentSubText title="Jouw website bezoekers, volgers en (zakelijke) netwerk zijn geld waard. Bij UNIVERSALES kun je geld verdienen per lead of per sale, maar nog belangrijker, door innovatieve tools en persoonlijke ondersteuning helpt UNIVERSALES je bij het maximaliseren van je inkomsten." />
          <ContentSubTitle title="Extra voordelen" />
          <ContentSubList
            list={[
              <li className="text-14 md:text-16" key={0}>
                Verdien extra door nieuwe sales agents aan te melden uit je eigen netwerk.
              </li>,
              <li className="text-14 md:text-16" key={1}>
                Werf klanten via onze database of uit je eigen netwerk.
              </li>,
              <li className="text-14 md:text-16" key={2}>
                Persoonlijke begeleiding en sales training door onze sales professionals.
              </li>,
              <li className="text-14 md:text-16" key={3}>
                Hoe meer je verkoopt, hoe meer je verdient aan het eind van de maand.
              </li>,
              <li className="text-14 md:text-16" key={4}>
                Bouw je netwerk uit, leer en werk samen met andere sales agents.
              </li>,
              <li className="text-14 md:text-16" key={5}>
                Je verkoopt alleen voor absolute A-merken.
              </li>,
              <li className="text-14 md:text-16" key={6}>
                Maak gebruik van flexibele werkplekken met de beste faciliteiten.
              </li>,
            ]}
          />
        </div>
      ),
    },
  ])

  return (
    <>
      <div className="w-full mb-40 md:mb-[110px] grid grid-cols-1 gap-12 md:gap-30 relative">
        <div className="absolute w-[1160px] transform top-[50%] translate-x-[50%] translate-y-[-50%] right-[-250px] rotate-[-10deg]">
          <img src={Triangle} alt="" className="w-full" />
        </div>
        <div className="w-full flex justify-center">
          <div className="w-[580px] max-w-full text-white text-32 sm:text-48 md:text-64 font-bold leading-[40px] sm:leading-[50px] md:leading-[72px] text-center">
            De start van jouw <br />
            Sales carrière
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <Dotted />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 gap-100">
        {careers.map((career: CareerInterface, key: number) => (
          <div className="w-full" key={key}>
            {key % 2 === 0 ? (
              <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-40 md:gap-100" key={key}>
                <div className="w-full">
                  <div className="w-full flex justify-center">
                    <div className="w-[700px] max-w-full relative my-30 sm:my-60">
                      <div className="absolute -top-30 sm:-top-60 -bottom-30 sm:-bottom-60 -left-30 sm:-left-60 max-w-full w-[410px] rounded-20 bg-gradient-to-r from-purple to-blue"></div>
                      <div className="relative rounded-16 overflow-hidden">
                        <img src={career.icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-center">
                  <div className="w-[700px] max-w-full grid grid-cols-1 gap-32">
                    <div className="w-full text-white text-36 sm:text-48 font-bold">{career.title}</div>
                    <div className="w-full">{career.content}</div>
                    <div className="w-full flex">
                      <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                        <Button title={"Meld je aan"} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col-reverse xl:flex-col xl:grid xl:grid-cols-2 gap-100" key={key}>
                <div className="w-full grid grid-cols-1 gap-20 md:gap-32">
                  <div className="w-full text-white text-36 sm:text-48 font-bold">{career.title}</div>
                  <div className="w-full">{career.content}</div>
                  <div className="w-full flex">
                    <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                      <Button title={"Meld je aan"} />
                    </Link>
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full relative my-30 sm:my-60">
                    <div className="absolute -top-30 sm:-top-60 -bottom-30 sm:-bottom-60 -right-30 sm:-right-60 max-w-full w-[410px] rounded-20 bg-gradient-to-r from-purple to-blue"></div>
                    <div className="relative flex justify-center overflow-hidden rounded-16">
                      <img src={career.icon} alt="" />
                      <Wrapper open={key === 1}>
                        <div className="absolute w-full h-full top-0 left-0 bg-blue-purple bg-opacity-25"></div>
                      </Wrapper>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default ContentCareer
