import { ReactComponent as Logo } from "assets/img/logo.svg"
import { useProps } from "contexts/PropsContext"
import { SOCIAL, URL } from "libs/constants"
import { openLink } from "libs/functions"
import { LinkInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { FaBars, FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa"
import { Link } from "react-router-dom"

const Nav = () => {
  const { path } = useProps()

  const [open, setOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const [links] = useState([
    { title: "Home", link: URL.HOME },
    { title: "Platform", link: URL.CONTENT },
    { title: "Over ons", link: URL.ABOUT },
    { title: "Adverteerders", link: URL.ADVERTISING },
    { title: "Buitenland", link: URL.EVOLUTION },
    { title: "Contact", link: URL.CONTACT },
  ])

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 min-w-[300px] z-30 transition ${
          scrollPosition > 0 || open ? "bg-background" : ""
        }`}
      >
        <div className="w-full h-80 flex justify-center pl-20 sm:pl-30 pr-0 lg:pr-30">
          <div className="w-full max-w-screen-2xl flex justify-between items-center">
            <Link to={URL.HOME} className="flex items-center gap-16">
              <Logo className="w-40 h-40" />
              <div className="hidden sm:flex text-18 font-bold text-white">UNIVERSALES</div>
            </Link>
            <div className="hidden xl:flex items-center gap-20 lg:gap-30">
              {links.map((link: LinkInterface, key: number) => (
                <Link
                  to={link.link}
                  className={`text-14 h-80 flex items-center font-semibold hover:text-white ${
                    link.link === path ? "text-white" : "text-grey"
                  }`}
                  key={key}
                >
                  {link.title}
                </Link>
              ))}
            </div>
            <div className="hidden xl:flex items-center gap-30">
              <button onClick={(e) => openLink(e, SOCIAL.LINKEDIN)}>
                <FaLinkedin className="text-24 text-white" />
              </button>
              <button onClick={(e) => openLink(e, SOCIAL.FACEBOOK)}>
                <FaFacebookF className="text-24 text-white" />
              </button>
              <button onClick={(e) => openLink(e, SOCIAL.INSTAGRAM)}>
                <FaInstagram className="text-24 text-white" />
              </button>
            </div>
            <div className="flex xl:hidden w-80 h-80 relative">
              <button onClick={() => setOpen(!open)} className="flex w-80 h-80 relative items-center justify-center">
                <FaBars className="text-24 text-white" />
              </button>
              {open ? (
                <div className="fixed top-[78px] left-0 p-20 pt-4 sm:p-30 sm:pt-10 flex flex-wrap justify-center gap-12 bg-background w-screen min-w-[300px]">
                  {links.map((link: LinkInterface, key: number) => (
                    <Link
                      to={link.link}
                      className={`w-full md:w-auto text-14 h-30 px-10 flex items-center font-semibold hover:text-white justify-center ${
                        link.link === path ? "text-white" : "text-grey"
                      }`}
                      key={key}
                    >
                      {link.title}
                    </Link>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nav
