import Triangle from "assets/img/triangle.png"
import EvolutionPerksColumn from "components/evolution/EvolutionPerksColumn"
import { PerkInterface } from "libs/interfaces"
import React, { useState } from "react"

const EvolutionPerks = () => {
  const [offered] = useState<PerkInterface[]>([
    { title: "Aantrekkelijk salarispakket*" },
    { title: "Verhuispakket ( Incl. vlucht + accommodatie voor 3 weken)" },
    { title: "3 weken doelgerichte training" },
    { title: "Maandelijkse performance bonus" },
    { title: "Taxi Drop Off Service voor avond- en nachtdiensten" },
    { title: "Sportschoolabonnement" },
    { title: "Team building activiteiten en evenementen" },
    { title: "Persoonlijke ontwikkeling en mogelijkheid tot groei binnen de organisatie" },
    { title: "Diverse leerzame workshops" },
    {
      title: "*Het totale salaris loopt op tot een gemiddelde van €24.000 tot €29.000 bruto per jaar.*",
      special: true,
    },
    { title: "*Dit is tevens afhankelijk van prestaties.*", special: true },
  ])

  const [requirements] = useState<PerkInterface[]>([
    { title: "Uitstekende kennis van Nederlands/Vlaams in woord en geschrift" },
    { title: "Je bent gedreven in het leveren van uitstekende service" },
    { title: "Je hebt een positieve, proactieve werkinstelling" },
    { title: "Je bent flexibel en hebt een sterk gevoel van verantwoordelijkheid" },
    { title: "Je bent een teamspeler en fungeert goed in een dynamische werkomgeving" },
  ])

  return (
    <>
      <div className="relative z-10  w-full">
        <div className="absolute w-[1160px] transform translate-x-[-50%] translate-y-[-50%] left-[-250px] rotate-[-0deg] top-40">
          <img src={Triangle} alt="" className="w-full" />
        </div>
        <div className="relative grid w-full grid-cols-1 gap-100 py-40 sm:py-60 md:py-100">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-60 sm:gap-80 md:gap-100 items-start">
            <EvolutionPerksColumn title={"Dit biedt Evolution Gaming jou:"} perks={offered} />
            <EvolutionPerksColumn title={"Functie eisen:"} perks={requirements} />
          </div>
        </div>
      </div>
    </>
  )
}

export default EvolutionPerks
