import Nav from "components/nav/Nav"
import React from "react"

const NotFound = () => {
  return (
    <>
      <Nav />
      <div className="w-full mt-[114px] flex items-center justify-center p-20 sm:px-30 sm:py-50">
        <div className="w-full max-w-screen-xl">
          <h1 className="w-full text-center mb-10 text-20 sm:text-26 text-white font-semibold">Not found</h1>
        </div>
      </div>
    </>
  )
}

export default NotFound
