import ShadeTop from "assets/img/shade_top.png"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import PrivacyBox from "components/privacy/PrivacyBox"
import PrivacyCookie from "components/privacy/PrivacyCookie"
import PrivacyItem from "components/privacy/PrivacyItem"
import PrivacyList from "components/privacy/PrivacyList"
import PrivacySubTitle from "components/privacy/PrivacySubTitle"
import PrivacyText from "components/privacy/PrivacyText"
import PrivacyTitle from "components/privacy/PrivacyTitle"
import PrivacyWarning from "components/privacy/PrivacyWarning"
import { openLink } from "libs/functions"
import React from "react"

const Privacy = () => {
  return (
    <>
      <Nav />
      <div className="w-full relative">
        <div className="absolute top-[-465px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
      </div>
      <div className="w-full mt-80 flex items-center justify-center p-20 md:px-30 md:py-50 relative z-20">
        <div className="w-full max-w-screen-2xl">
          <div className="w-full my-0 2xl:mt-[60px] 2xl:mb-[60px] grid grid-cols-1 gap-20">
            <PrivacyTitle title="Universales – ons privacy policy, jouw privacy." />
            <PrivacyText
              text={
                <>
                  Bij Universales willen wij volledig transparant zijn over hoe wij (mogelijk) gebruik – of in
                  juridische taalgebruik: “Verwerken” – maken van je Persoonsgegevens zodra je met ons in contact komt,
                  op welke manier dan ook.
                  <br />
                  <br />
                  In dit document zetten wij stap voor stap uit hoe wij omgaan met jouw privacy in de verschillende
                  contexten waarin wij elkaar te mogen ontmoeten. We raden iedereen aan de tijd te nemen om het dit
                  document lezen, aangezien wij het erg belangrijk vinden dat onze klanten, bezoekers en andere
                  mogelijke Betrokkenen weten dat hun privacy gerespecteerd wordt.{" "}
                </>
              }
            />
            <PrivacyTitle title="1. Wie zijn wij en waarom deze (uitgebreide) Privacy policy?" />
            <PrivacyText
              text={
                <>
                  Wij – Universales B.V. (hierna “UNIVERSALES”, “wij” of “ons”) – beschrijven in dit document aan jou –
                  de Betrokkene (hierna “je/jij/jou”, de “klant” of “Betrokkene”) –hoe wij omgaan met jouw privacy
                  tijdens het verzamelen en verder Verwerken van jouw Persoonsgegevens in overeenstemming met de
                  Algemene Verordening Gegevensbescherming (kortweg: de AVG). Met deze Privacy policy willen wij jou in
                  detail informeren over onder andere: ons als Verwerkingsverantwoordelijke van jouw Persoonsgegevens,
                  over in welke (verschillende) hoedanigheden wij met elkaar in contact kunnen komen, over de Verwerking
                  van de Persoonsgegevens in het algemeen, over de Doeleinden waarop wij ons baseren bij het Verwerken
                  van de Persoonsgegevens, de Rechtsgrond(en) waarop de Verwerking van de Persoonsgegevens gebaseerd is,
                  over de Bewaartermijnen die wij hanteren bij het Verwerken van de Persoonsgegevens, over het gebruik
                  van Cookies en trackingtechnologieën op onze website(s), over of en onder welke voorwaarden
                  Gegevensoverdracht plaatsvindt naar andere organisaties/landen, over welke organisatorische en
                  technische maatregelen wij hebben genomen en welke rechten jij kunt uitoefenen in het kader van de
                  Verwerking van de Persoonsgegevens. Wij hebben wij getracht de meest relevante onderwerpen zo
                  zorgvuldig en begrijpelijk mogelijk voor jou uit te werken. Het is echter mogelijk dat, afhankelijk
                  van de specifieke omstandigheden en/of de relatie tussen ons, andere Privacy documentatie,
                  protocollen, Verwerkersovereenkomsten, disclaimer(s), algemene voorwaarden, deelnamevoorwaarden en/of
                  andere documenten van toepassing zijn op de (zakelijke) relatie die wij met elkaar hebben of aangaan.
                </>
              }
            />
            <PrivacyBox
              text={
                <>
                  De Verwerkingsverantwoordelijke – Wie zijn wij:
                  <br />
                  <br />
                  UNIVERSALES B.V.
                  <br />
                  Adres: Ankersmidplein 2, 1506 CK te Zaandam
                  <br />
                  E-mail:{" "}
                  <button onClick={(e) => openLink(e, "mailto:business@universales.work")} className="text-blue">
                    business@universales.work
                  </button>
                  <br />
                  KVK-nummer: KVK 81805802
                  <br />
                  btw-Nummer: NL862226314B01
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Let op: elke (niet-gedefinieerde) term in deze Privacy policy heeft, indien de term is gedefinieerd in
                  de AVG, dezelfde betekenis zoals in de AVG zelf. Je kunt de AVG in elke (Europese) taal vinden door{" "}
                  <button
                    className="text-blue"
                    onClick={(e) =>
                      openLink(
                        e,
                        "https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1532348683434&uri=CELEX:02016R0679-20160504"
                      )
                    }
                  >
                    hier te klikken
                  </button>
                  .
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Deze Privacy policy is voor het laatst herzien op 30 april 2022. UNIVERSALES behoudt zich te allen
                  tijde het recht voor om de huidige versie van deze Privacy policy te wijzigen of te herzien. We raden
                  je daarom aan om deze pagina en de Privacy Policy regelmatig te controleren om op de hoogte te blijven
                  van de laatste versie van deze Privacy policy. Indien wij het nodig vinden om ingrijpende wijzigingen{" "}
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  In het geval je op enig moment contact met ons wilt opnemen over (de bescherming van) je
                  Persoonsgegevens en/of daaraan gerelateerde zorg(en), aarzel dan niet om contact met ons op te nemen
                  via de bovenstaande contactgegevens!
                </>
              }
            />
            <PrivacyTitle title="2. Categorieën van (mogelijke) Betrokkenen" />
            <PrivacyText
              text={
                <>
                  Wij – jij en UNIVERSALES – kunnen in verschillende situaties met elkaar betrokken zijn. In deze
                  verschillende situaties, kunnen wij (verschillende sets van) jouw Persoonsgegevens Verwerken. Om te
                  verduidelijken in welke verschillende situaties wij (mogelijk) Persoonsgegevens van jouw Verwerken,
                  vind je hieronder een (niet uitputtende) lijst van de meest voorkomende situaties waarin wij met
                  elkaar betrokken kunnen zijn:
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title:
                    "Potentiële, vroegere en huidige (online) klanten (die geïnteresseerd zijn in ons online aanbod van diensten);",
                },
                {
                  title:
                    "Zakelijke partners/contacten, gelieerde partners en/of andere betrokken gelieerde partijen (in het bijzonder (Derde) partijen die betrokken zijn bij de bedrijfsvoering en/of totstandkoming, facilitering of realisatie van onze (online) diensten);",
                },
                {
                  title:
                    "(Web)bezoekers op onze website of (via) online (social media) platforms (bijvoorbeeld Facebook en Instagram);",
                },
                {
                  title:
                    "(Wettelijke) vertegenwoordigers, gemachtigden, contactpersonen en/of werknemers die betrokken zijn bij de (zakelijke) relatie tussen één (of meer) van de bovengenoemde categorieën van Betrokkene(en) en UNIVERSALES;",
                },
                { title: "Personeel van UNIVERSALES;" },
                { title: "Andere categorieën van Betrokkenen die hierin niet specifiek worden genoemd" },
              ]}
            />
            <PrivacySubTitle title="2.1.1 Het verzamelen en Verwerken van Persoonsgegevens" />
            <PrivacyText
              text={
                <>
                  Maar laten wij beginnen met te definiëren wat er bedoeld wordt met Persoonsgegevens. In deze Privacy
                  policy worden &apos;Persoonsgegevens&apos; gedefinieerd als: “alle informatie met betrekking tot een
                  geïdentificeerde of identificeerbare natuurlijke persoon; een identificeerbare natuurlijke persoon is
                  iemand die direct of indirect kan worden geïdentificeerd, met name, maar niet beperkt tot, door te
                  verwijzen naar een identificerende kenmerk (identificator)zoals een naam, een identificatienummer,
                  locatiegegevens, een online identificator of één of meer specifieke elementen die kenmerkend zijn voor
                  de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit van
                  die natuurlijke persoon”. Of simpel gezegd: elk stukje informatie dat alleen, of in combinatie met
                  andere informatie, uiteindelijk een (bepaald) persoon zou kunnen identificeren.
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  We zullen enkel Persoonsgegevens verzamelen en verwerken die noodzakelijk zijn voor (het kunnen
                  bereiken van) onze (vooraf) gespecificeerde Doeleinden voor Verwerking, zoals nader uiteengezet in
                  paragraaf 4 van deze Privacy policy. Ook beoordelen wij periodiek de Persoonsgegevens die wij van jou
                  hebben Verwerkt en verwijderen wij alles wat wij niet (langer) nodig hebben, niet (meer) gebruiken of
                  waarvoor wij niet (langer) een Doeleinde of Rechtsgrond hebben.
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Verder Verwerken wij voornamelijk Persoonsgegevens – in overeenstemming met onze Doeleinden – die wij
                  (rechtstreeks) van onze klanten, zakenpartners/contacten, gelieerde partners en/of andere gelieerde
                  partijen ontvangen of verzamelen, evenals van andere categorieën van Betrokkene(n) zoals vermeld onder
                  paragraaf 2, dan wel ontvangen/verzameld van webbezoekers die onze website(s), (sociale media)
                  platforms en/of andere (online) applicaties gebruiken/bezoeken.
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Als je op dit moment afvraagt wat er wordt bedoeld met Verwerken of een synoniem daarvan, dan leesje
                  hier de definitie: “Verwerking” : een bewerking of een geheel van bewerkingen met betrekking tot
                  persoonsgegevens of een geheel van persoonsgegevens, al dan niet uitgevoerd via geautomatiseerde
                  procedés, zoals het verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen,
                  opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere
                  wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van
                  gegevens”. Of simpel gezegd: elk gebruik van Persoonsgegevens van (EU) Betrokkenen.
                </>
              }
            />
            <PrivacyBox
              text={
                <>
                  Let op: onze (digitale) diensten, website(s) en/of andere (activiteiten op) online (sociale) platforms
                  hebben niet de intentie om Persoonsgegevens te verzamelen van (website)bezoekers jonger dan 16 jaar of
                  bezoekers in de leeftijdscategorie van 16-18 jaar. In het ongebruikelijke geval dat wij gegevens van
                  een minderjarige toch moeten Verwerken, doen wij dit alleen met de uitdrukkelijke Toestemming van de
                  ouder(s) of voogd(en). Helaas kunnen wij nooit (met zekerheid) de daadwerkelijke leeftijd bevestigen
                  of een minderjarige technisch verbieden om de website(s) en/of social media platforms te bezoeken
                  aangezien deze (ook) toegankelijk zijn voor minderjarigen. Om die reden raden wij je aan betrokken te
                  zijn bij de online activiteiten van minderjarigen om te voorkomen dat zij hun Persoonsgegevens
                  verspreiden zonder de Toestemming van de ouder(s) of voogd(en). Als je van mening bent dat wij ten
                  onrechte de Persoonsgegevens van een minderjarige hebben Verwerkt, neem dan contact met ons op
                  (paragraaf 1) zodat wij dit z.s.m kunnen controleren en indien nodig direct kunnen corrigeren.
                  <br />
                  <br />
                  Houd er ook rekening mee dat als je ons Persoonsgegevens van andere personen verstrekt (bijvoorbeeld
                  van contacten, collega&apos;s, kennissen, vrienden, familieleden en/of andere personen), je er zorg
                  voor moet dragen dat de respectieve perso(o)n(en) op de hoogte is (of zijn) van deze Privacy policy en
                  slechts de Persoonsgegevens van anderen aan ons mag verstrekken als je daarvoor hun expliciete
                  Toestemming hebt gekregen en op voorwaarde dat dergelijke Persoonsgegevens nauwkeurig en
                  waarheidsgetrouw zijn.
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Er zijn verschillende manieren waarop je onsjouw Persoonsgegevens kunt verstrekken en/of waarmee wij
                  Persoonsgegevens van jou verzamelen/Verwerken. Voor het behoud van overzicht verwijzen wij in deze
                  Privacy policy alleen naar de meest voorkomende manieren waarop Persoonsgegevens worden
                  verzameld/verwerkt door UNIVERSALES De categorieën van Betrokkenen die in paragraaf 2 genoemd worden,
                  kunnen aan ons in ieder geval op de volgende manieren hun Persoonsgegevens aan ons verstrekken:
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title: "Het bezoeken en/of navigeren van onze website(s);",
                },
                {
                  title: "Het aangaan van een (online) overeenkomst;",
                },
                {
                  title: "Door het invullen van (web)formulieren;",
                },
                {
                  title: "Door te registreren voor onze (digitale) diensten/nieuwsbrief;",
                },
                {
                  title:
                    "Door te communiceren via e-mail, telefoon, (aangetekende) post en/of vergelijkbare communicatiemiddelen;",
                },
                {
                  title:
                    "Door te reageren op onze (algemene) berichten en/of het versturen van (privé)berichten via social media platforms (bijvoorbeeld Facebook of Instagram);",
                },
                {
                  title:
                    "Door uit eigen beweging je Persoonsgegevens aan ons te verstrekken via een van de bovenstaande of vergelijkbare methoden;",
                },
                {
                  title:
                    "Andere manieren van het contacteren/ontvangen/Verwerken (van Persoonsgegevens) die in deze Privacy policy niet in overweging worden genomen.",
                },
              ]}
            />
            <PrivacyText
              text={
                <>
                  Voor zover dit voor ons noodzakelijk en toegestaan is, kunnen wij naast de bovengenoemde methoden van
                  gegevensverzameling/Verwerking ook andere Persoonsgegevens van jou verzamelen/Verwerken. UNIVERSALES
                  kan bijvoorbeeld ook in het kader van het aangaan of uitvoeren van een overeenkomst met jou
                  Persoonsgegevens verkrijgen uit openbaar toegankelijke bronnen (bijvoorbeeld handelsregisters,
                  schuldregisters, pers, internet en sociale media) en/of kan dergelijke informatie Verwerken van
                  zakenpartners/contacten, gelieerde partners, andere gelieerde partijen en/of anderszins betrokken
                  (Derde) partijen (bijvoorbeeld wettelijke vertegenwoordigers, deskundige/specialisten, autoriteiten en
                  ICT-dienstverleners), op voorwaarde dat wij voor de Verwerking van deze Persoonsgegevens een
                  (specifiek) vooraf bepaald Doeleinde en een Rechtsgrond hebben. Over het algemeen zullen wij
                  dergelijke informatie van de meeste van onze Betrokkenen zoals (web)bezoekers of klanten echter niet
                  verwerken. Mochten wij dergelijke informatie toch (moeten) Verwerken, dan zorgen wij ervoor dat je
                  hierover vooraf goed geïnformeerd wordt.
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Afgezien van de (gevraagde) Persoonsgegevens die je rechtstreeks aan ons verstrekt of uit eigen
                  beweging wordt verstrekt, zijn de categorieën Persoonsgegevens die UNIVERSALES verzamelt en Verwerkt
                  (opgevraagd of verkregen zoals hierboven gespecificeerd) afhankelijk van het type (zakelijke) relatie
                  je hebt bij ons. Sommige van de volgende categorieën Persoonsgegevens zijn daarom mogelijk niet van
                  toepassing op jouw (zakelijke) relatie met ons. Voor de transparantie tref je de volgende – niet
                  uitputtende – lijst van categorieën van Persoonsgegevens die wij (mogelijk) Verwerken tijdens onze
                  (zakelijke) relatie met een vooraf bepaalde Doeleinde en Rechtsgrond daarvoor, zoals verder zal worden
                  uitgelegd in paragraaf 4 van deze Privacy policy. Verder Verwerken wij niet meer (Persoons)gegevens
                  dan noodzakelijk is voor het Doeleinde waarvoor wij jouw Persoonsgegevens Verwerken (zo zullen wij
                  enkel je e-mailadres Verwerken indien je je inschrijft voor onze nieuwsbrief, en vragen wij je niet
                  naar je naam, adres of telefoonnummer; deze informatie hebben wij namelijk niet nodig om je onze
                  nieuwsbrief te kunnen sturen):
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title:
                    "(Voor- en achternaam/zakelijke) naam, e-mailadres, (verzend-/factuur)adres en telefoonnummer;",
                  prefix: "A",
                },
                {
                  title:
                    "Bankrekeningnummer (meestal de laatste 4 cijfers), dossier-/zaakreferenties, ordernummers/referenties (in geval van een online bestelling: IP-adres), en/of alle andere (Persoons)gegevens die nodig of nuttig zijn bij de Uitvoering van de overeenkomst(en) tussen ons;",
                  prefix: "B",
                },
                {
                  title: "Informatie/uittreksels uit (openbare of besloten) registers (bijv. Kamer van Koophandel);",
                  prefix: "C",
                },
                {
                  title: "Informatie in verband met je professionele rol en activiteiten;",
                  prefix: "D",
                },
                {
                  title: "Persoonsgegevens over jou in correspondentie en gesprekken met andere (Derde) partijen;",
                  prefix: "E",
                },
                {
                  title: "Informatie over krediet-/debet beoordeling;",
                  prefix: "F",
                },
                {
                  title:
                    "Informatie die aan ons is verstrekt door met jouw verbonden personen zoals vrienden, familie, dviseurs, wettelijke vertegenwoordigers en/of op andere manieren betrokken (Derde) partijen bijvoorbeeld in de vorm van referenties, afleveradres(sen), volmachten, informatie over wettelijke voorschriften zoals anti-witwassen en exportbeperkingen voor Doeleinden zoals het sluiten of uitvoeren van overeenkomsten met jou);",
                  prefix: "G",
                },
                {
                  title:
                    "Informatie van of met betrekking tot leverancier/distributeurs en andere zakenpartners met het oog op het bestellen of leveren van diensten aan jou of door jou;",
                  prefix: "H",
                },
                {
                  title:
                    "Gegevens over jou (Verwerkt) vanaf social media of internet (voor zover nodig in verband met bijvoorbeeld sollicitaties, reviews, marketing/sales, personalisatie van onze (digitale) diensten et cetera);",
                  prefix: "I",
                },
                {
                  title:
                    "(Algemene) statistische gegevens in verband met jouw gebruik van onze websites (bijv. geanonimiseerde IP-adres(sen), informatie over je apparaat en instellingen, informatie verzameld aan de hand van de door ons gebruikte Cookies, datum en tijd van jouw bezoek, websites/pagina's en opgehaalde inhoud, gebruikte applicaties, verwijzende website, lokalisatiegegevens en/of andere algemene statistische gegevens over het gebruik van onze website en/of inhoud daarvan);",
                  prefix: "J",
                },
                {
                  title:
                    "Gegevens die zijn ontvangen in verband met administratieve of gerechtelijke procedures (in het onwaarschijnlijke geval dat er een conflict tussen ons is);",
                  prefix: "K",
                },
                {
                  title:
                    "(In sommige gevallen) in het kader van onze zakelijke relatie: bedrijfsgegevens, financiële informatie, handtekening, Persoonsgegevens in correspondentie tussen jou en andere (Derde) partijen evenals alle andere (Persoons)gegevens die nodig, Wettelijk verplicht en/of nuttig zijn tijdens de (zakelijke) relatie tussen UNIVERSALES en jou;",
                  prefix: "L",
                },
                {
                  title:
                    "Gegevens over jouw fysieke kenmerken (in de vorm van afbeeldingen en/of video's van opgenomen situaties) verkregen via videobewaking ten behoeve van het beschermen van onze bezoekers/klanten, medewerkers en/of hun/onze eigendommen. Zie in dit verband ook ons Cameraprotocol;",
                  prefix: "M",
                },
                {
                  title:
                    "(Identificatie)informatie zoals je geboortedatum en foto uit een officieel wettelijk document om bijvoorbeeld een arbeidsovereenkomst met elkaar te kunnen sluiten of bepaalde diensten aan je te kunnen leveren;",
                  prefix: "N",
                },
                {
                  title:
                    "Alle overige (Persoons)gegevens die nodig en/of relevant zijn voor het onderhouden van onze (zakelijke) relatie met jou (bijv. feedback, (e-mail)correspondentie, uit eigen initiatief verstrekte informatie – bijv. sociaal-demografische gegevens – et cetera);",
                  prefix: "O",
                },
                {
                  title:
                    "In het kader van een (open) sollicitatie kunnen wij jouw Persoonsgegevens zoals contactgegevens, CV, cijferlijsten, referentiebrieven, diploma's, een motivatiebrief en/of andere (functie)specifieke informatie Verwerken met als doel het beoordelen van een mogelijke tewerkstelling. Voor screening(Doeleinden) kunnen wij ook online/openbaar beschikbare informatie over jou Verwerken, zoals je sociale-mediaprofielen.",
                  prefix: "P",
                },
              ]}
            />
            <PrivacyTitle
              title="3. De meest voorkomende Doeleinden, Rechtsgrond en Bewaartermijn(en) voor de Verwerking van
jouw Persoonsgegevens"
            />
            <PrivacyText
              text={
                <>
                  Wij gebruiken de Persoonsgegevens hoofdzakelijk met als Doeleinde het sluiten en uitvoeren van
                  overeenkomsten met onze klanten en zakenpartners, met name in verband met: het uitvoeren/leveren van
                  onze diensten in overeenstemming met onze overeenkomst(en), het leveren van onze diensten en inhoud
                  alsmede het uitbreiden, ontwikkelen van en/of investeren in nieuwe diensten en/of zakelijke kansen. We
                  kunnen jouw Persoonsgegevens ook gebruiken voor het Doeleinde om jou (informatie over) onze andere
                  (zeer interessante) dienst(en) te verstrekken, alsmede voor het afnemen van diensten van onze
                  zakelijke partners/contacten, gelieerde partners en/of andere betrokken gelieerde partijen. Ook
                  (kunnen) de Persoonsgegevens worden verwerkt om te kunnen voldoen aan onze binnen- en buitenlandse
                  Wettelijke verplichtingen. Het is ook mogelijk dat je betrokken bent bij onze gegevensverwerking in de
                  hoedanigheid van andere categorieën van Betrokkenen, zoals vermeld in paragraaf 2.
                  <br />
                  <br />
                  Om een zo duidelijk mogelijk overzicht te geven, vind je hieronder de meest voorkomende Doeleinden die
                  wij (kunnen) inroepen bij het Verwerken van jouw (of andere individuen hun) Persoonsgegevens. In deze
                  paragraaf leggen wij ook uit op welke Rechtsgrond de Verwerking van jouw Persoonsgegevens plaatsvindt
                  en welke Bewaartermijn wij hanteren voor de Persoonsgegevens die wij Verwerken. Houd er echter
                  rekening mee dat deze lijst niet uitputtend is; wij kunnen jouw Persoonsgegevens ook Verwerken in het
                  geval wij een Doeleinde hebben die verenigbaar is met een &apos;oorspronkelijk&apos; Doeleinde zoals
                  vermeld onder paragraaf 4.
                </>
              }
            />
            <PrivacyItem
              title="Het (online) aangaan en uitvoeren van overeenkomsten met betrekking tot onze diensten
en/of het aanmaken van een online account:"
            />
            <PrivacyText
              text={
                <>
                  Jouw Persoonsgegevens (paragraaf 3: A, B & H) kunnen door ons worden Verwerkt voor het aangaan en
                  uitvoeren van (online) overeenkomsten in het kader van de inkoop en levering van onze (online)
                  diensten en kunnen verder worden gebruikt voor onder meer: het onderhouden van contact over en met
                  betrekking tot onze (online) overeenkomst, voor facturatie/betalingsdoeleinden, voor (online)
                  accountbeheer (en overige administratieve zaken) en voor het onderhouden van onze (online) zakelijke
                  relaties met jou. Ook is het mogelijk om een online account aan te maken op onze website(s). Bij het
                  aanmaken van een onlineaccount Verwerken wij (mogelijk bepaalde) Persoonsgegevens (paragraaf 3: A) om
                  een eenvoudig en geoptimaliseerd bestelproces mogelijk te maken. Bij het inloggen hoef je als klant
                  jouw Persoonsgegevens niet (opnieuw) in te vullen om een bestelling bij ons te kunnen plaatsen.
                  <br />
                  <br />
                  Wij Verwerken de Persoonsgegevens op de Rechtsgrond van ‘Uitvoering van de overeenkomst’. Indien wij
                  geen beroep kunnen doen op de Rechtsgrond van Uitvoering van de overeenkomst, kunnen wij de
                  Rechtsgrond van ‘Gerechtvaardigd belang’ inroepen, voor zover wij een ‘Gerechtvaardigd belang’ hebben.
                  We kunnen de Persoonsgegevens ook Verwerken vanwege een &apos;Wettelijke verplichting&apos; of soms
                  (bijvoorbeeld in het geval van het aanmaken van een account op de website) door
                  &apos;Toestemming&apos; te vragen. Bovengenoemde gegevens worden bewaard zo lang als nodig is om de
                  overeenkomst tussen ons uit te voeren en/of onze diensten aan jou te kunnen leveren. Indien wij niet
                  langer de Persoonsgegevens gebruiken, dan verwijderen of anonimiseren wij jouw Persoonsgegevens,
                  tenzij wij Wettelijk verplicht zijn (een deel van) deze langer te bewaren of een ander Doeleinde en/of
                  Rechtsgrond hebben voor het voortzetten van de Verwerking van deze Persoonsgegevens. De
                  financiële/fiscale gegevens met betrekking tot onze zakelijke relaties worden bewaard zo lang als
                  nodig is om te voldoen aan onze wettelijke bewaarplicht voor onze (belasting)administratie (minimaal
                  zeven jaar).
                </>
              }
            />
            <PrivacyItem title="Om contact met je op te nemen (of te antwoorden) (nadat je contact met ons heeft opgenomen):" />
            <PrivacyText
              text={
                <>
                  Indien je ons vragen stelt, verzoeken doet, online (contactformulieren) invult of gebruik maakt van de
                  alternatieve berichtmogelijkheden op onze online (social media) platformen/website(s), dan kunnen wij
                  jouw Persoonsgegevens Verwerken (afhankelijk van de informatie die je ons verstrekt: zie paragraaf 3:
                  A - O). Met het gebruik van deze Persoonsgegevens (proberen) wij jou te bereiken en - afhankelijk van
                  de omvang van je vraag, verzoek of aanmelding – zo spoedig mogelijk en correct te adresseren. Wij
                  Verwerken de Persoonsgegevens op grond van de Rechtsgrond &apos;Uitvoering van de overeenkomst&apos;
                  of op basis van ons ‘Gerechtvaardigd belang&apos; om jou(w vragen) te (be)antwoorden en/of om
                  (uiteindelijk) het contact met je aan te gaan in het geval dat wij geen beroep kunnen doen op de
                  Rechtsgrond van Uitvoering van de overeenkomst. In sommige gevallen kunnen wij ook om jouw expliciete
                  &apos;Toestemming&apos; vragen. In ieder geval zullen wij jouw Persoonsgegevens bewaren totdat wij
                  denken dat je tevreden bent met onze reactie, tenzij wij voor het verder Verwerken van de
                  Persoonsgegevens een ander Doeleinde of Rechtsgrond hebben die een langere Bewaartermijn voor het
                  gebruik van deze Persoonsgegevens rechtvaardigt. Als je je hebt aangemeld voor onze diensten, dan
                  bewaren wij de Persoonsgegevens totdat de overeenkomst tussen ons is uitgevoerd, tenzij wij Wettelijk
                  verplicht zijn (een deel van) deze langer te bewaren of een ander Doeleinde en/of Rechtsgrond hiervoor
                  hebben. De financiële/fiscale gegevens met betrekking tot onze zakelijke relaties worden bewaard
                  zolang als nodig is om te voldoen aan onze Wettelijke verplichting tot het bewaren van onze
                  (belasting)administratie (minimaal zeven jaar).
                </>
              }
            />
            <PrivacyItem title="Voor marketing, reclame en markt/opinieonderzoek van onze (digitale) diensten:" />
            <PrivacyText
              text={
                <>
                  Wanneer je ons online (via onze website(s)) bezoekt, kunnen jouw Persoonsgegevens (zie paragraaf 3: A,
                  C, D, I, J & O) worden Verwerkt voor de marketing en reclame van onze (digitale) diensten en
                  product(inhoud) of die van onze (aangesloten) partners/contacten, gelieerde partners en/of andere
                  gelieerde partijen, mits je geen bezwaar hebt gemaakt tegen het gebruik van jouw Persoonsgegevens voor
                  dit Doeleinde.
                  <br />
                  <br />
                  We Verwerken deze informatie voornamelijk op de Rechtsgrond &apos;Toestemming&apos; of op de
                  Rechtsgrond van ons &apos;Gerechtvaardigd belang&apos; (bijv. bedrijfscontinuïteit) om deze
                  Persoonsgegevenste Verwerken. We bewaren deze informatie zolang wij jouw Toestemming hebben en/of (een
                  van) onze Gerechtvaardigde belang(en) kunnen uitoefenen. In ieder geval worden jouw Persoonsgegevens
                  voor dit Doeleinde na drie jaar verwijderd, tenzij wij anders met elkaar zijn overeengekomen of indien
                  wij op dat moment nog een actieve zakelijke relatie met elkaar hebben.
                </>
              }
            />
            <PrivacyItem title="Het aanbieden, optimaliseren en ontwikkelen van onze website, (digitale) content, diensten:" />
            <PrivacyText
              text={
                <>
                  Wanneer je gebruik maakt van onze website(s) en/of (digitale) diensten, verkrijgen wij (mogelijk)
                  algemene bezoekgegevens (zie paragraaf 3: J). Deze gegevens gebruiken wij voor statistische analyses
                  (o.a. bezoek- en klikgedrag) van onze website(s). Met het gebruik van deze gegevens proberen wij een
                  soepele en stabiele verbindingsconfiguratie en comfortabel gebruik van de website(s) te garanderen,
                  onze systemen, veiligheid, stabiliteit te evalueren en de functionaliteit van de website(s) te
                  optimaliseren. We proberen deze gegevens zoveel mogelijk te Pseudonimiseren of Anonimiseren en
                  verstrekken deze gegevens niet aan andere (Derde) partijen die deze voor hun eigen Doeleinden zouden
                  kunnen gebruiken, tenzij hierover expliciet anders bepaald is in deze Privacy policy. In sommige
                  gevallen kunnen wij of gelieerde zakenpartners/contacten, gelieerde partners en/of andere betrokken
                  gelieerde partijen ook Persoonsgegevens Verwerken via Cookies tijdens het gebruik van onze website.
                  Wanneer dat het geval is, zullen wij jou daar expliciet over informeren. Zie paragraaf 5 voor meer
                  informatie over het gebruik van onze cookies en/of vergelijkbare technologieën.
                  <br />
                  <br />
                  Wij gebruiken bovengenoemde Persoonsgegevens op de Rechtsgrond van jouw ‘Toestemming’ of ons
                  ‘Gerechtvaardigd belang’ bij het monitoren en verbeteren van onze website(s) en/of (digitale)
                  diensten. Anonieme gegevens (die geen Persoonsgegevens meer bevatten) worden bewaard zolang deze
                  relevant voor ons zijn. Persoonsgegevens die via onze website(s) worden verzameld, worden bewaard
                  totdat jij je Toestemming intrekt of wij deze gegevens niet langer gebruiken. In ieder geval{" "}
                </>
              }
            />
            <PrivacyItem title="Verzenden en verstrekken van onze nieuwsbrief:" />
            <PrivacyText
              text={
                <>
                  We sturen een driemaandelijkse nieuwsbrief waarvoor wij de mogelijkheid bieden om je in te schrijven
                  (zie paragraaf 3: A). Na aanmelding ontvangt je per e-mail een periodieke nieuwsbrief met informatie,
                  nieuws en ontwikkelingen op het gebied van onze diensten, dienstverlening alsmede algemene
                  nieuwsberichten. Als je dit abonnement wilt opzeggen, kan dit op elk gewenst moment via de
                  uitschrijflink die je aantreft in de naar jou verzonden e-mail(s) met daarin de nieuwsbrief.
                  <br />
                  <br />
                  Je e-mailadres wordt pas toegevoegd aan de lijst van abonnees nadat wij jouw &apos;Toestemming&apos;
                  tijdens het registreren voor onze nieuwsbrief hebben ontvangen. We bewaren deze informatie totdat je
                  het abonnement opzegt of op een andere manier je Toestemming intrekt. In het geval dat jij je
                  Toestemming intrekt/afmeldt, registreren wij je e-mailadres op de blokkeerlijst zodat wij er zeker van
                  zijn dat je de nieuwsbrief niet opnieuw ontvangt.
                </>
              }
            />
            <PrivacyItem
              title="De veiligheid van onze klanten en die van onze medewerkers, eigendommen en producten
beschermen met cameratoezicht:"
            />
            <PrivacyText
              text={
                <>
                  Om de eigendommen en veiligheid van onze bezoekers/klanten en medewerkers te beschermen, evenals om
                  diefstal, overlast, vernieling, vandalisme en/of andere criminele activiteiten te
                  voorkomen/minimaliseren, dan wel om privaat- of strafrechtelijke procedures te ondersteunen, gebruiken
                  wij zichtbare cameratoezicht in sommige (toegankelijke) ruimtes en het voorportaal van onze
                  toiletruimte. Uiteraard zorgen wij ervoor dat je door middel van een sticker met het symbool van een
                  camera op de hoogte wordt gehouden van de aanwezigheid van cameratoezicht en de positie van de
                  (individuele) camera&apos;s. Met het gebruik van dit cameratoezicht kan UNIVERSALES Persoonsgegevens
                  (paragraaf 3: M) door middel van video-opnames Verwerken voor bewakingsdoeleinden. In de rest van deze
                  paragraaf lees je wat wij met de opnames doen, hoe lang wij ze bewaren, welke rechten je hebt en hoe
                  je deze kunt uitoefenen. Daarnaast hebben wij een specifiek Cameraprotocol waarin wij onze (AVG)
                  aanpak met betrekking tot het cameratoezicht in (meer) detail beschrijven. Mocht je hierover
                  uitgebreider willen worden geïnformeerd, vraag dan naar ons Cameraprotocol.
                  <br />
                  <br />
                  De Persoonsgegevens die wij (kunnen) Verwerken via cameratoezicht is gebaseerd op de Rechtsgrond van
                  ons &apos;Gerechtvaardigd belang&apos; met als Doeleinde de veiligheid en eigendommen van onze
                  bezoekers/klanten, medewerkers in en rond pand, alsmede ter voorkoming en/of het minimaliseren van
                  diefstal, overlast, vernieling, vandalisme, andere criminele activiteiten, dan wel ter ondersteuning
                  van privé- of strafrechtelijke procedures te ondersteunen en/of (zoveel mogelijk) te garanderen. De
                  impact op de privacy van Betrokkene(n) door ons gebruik van cameratoezicht is (steeds) beperkt tot
                  hetgeen noodzakelijk is voor de bescherming van de bezoekers/klanten, medewerkers en (hun) eigendommen
                  van UNIVERSALES, alsmede de objecten en personen die zich op dat moment in enigerlei wijze
                  toevertrouwd aan (de zorg van) UNIVERSALES, alsmede om een onderbouwde melding van incidenten mogelijk
                  te maken. Bij onze overwegingen omtrent cameratoezicht hebben wij zoveel mogelijk rekening gehouden
                  met de belangen van de personen van wie Persoonsgegevens worden Verwerkt. Zo plaatsen wij bijvoorbeeld
                  geen camera&apos;s op locaties waar mensen over het algemeen ongeschonden van hun privacy zouden
                  moeten kunnen genieten en zijn er allerlei maatregelen in het leven geroepen om de veiligheid van de
                  Verwerkte Persoonsgegevens te waarborgen. Vraag ook naar ons Cameraprotocol voor meer informatie over
                  ons gebruik van camera’s.
                </>
              }
            />
            <PrivacyItem title="Gebruik en uitwisseling van informatie verkregen uit onze camera-installatie" />
            <PrivacyText
              text={
                <>
                  In het (uitsluitende) geval van incidenten of vermoedens van strafbare feiten waarvan UNIVERSALES, één
                  van haar medewerkers of bezoekers/klanten aangifte wil doen bij een opsporingsinstantie, kan de
                  Verwerkte camerabeelden worden gebruikt om een Betrokkenen te identificeren. Hiertoe kunnen de beelden
                  ter ondersteuning van een aangifte van een strafbaar feit aan de opsporingsambtenaar ter beschikking
                  worden gesteld. De camerabeelden worden niet gedeeld met andere (Derde) partijen, tenzij daartoe een
                  (Wettelijke) verplichting bestaat of indien een Betrokkenen gebruikt maakt van het Recht op inzage
                  (van uitsluitend de op hem/haar betrekking hebbende Persoonsgegevens). De personen die betrokken zijn
                  bij de Verwerking van de camerabeelden zijn een uitdrukkelijke geheimhoudingsplicht overeengekomen.
                  UNIVERSALES maakt geen gebruik van gezichtsherkenning of andere methoden waarmee personen elektronisch
                  kunnen worden geïdentificeerd. Er wordt ook geen geluidsopname opgeslagen/Verwerkt tijdens het
                  cameratoezicht.
                </>
              }
            />
            <PrivacyItem title="Beveiliging en opslag van Persoonsgegevens verkregen uit onze camera-installatie" />
            <PrivacyText
              text={
                <>
                  De Verwerkte camerabeelden worden alleen binnen Nederland Verwerkt en opgeslagen. De genomen
                  maatregelen ten behoeve van de veiligheid van de videobeelden (en Persoonsgegevens) is gewaarborgd
                  zoals (nader) uiteengezet in paragraaf 7 van de Privacy policy. De toegang tot het videomateriaal is
                  strikt gereguleerd en kan aanvullende verificatie van de persoon vereisen in vergelijking met de
                  uitoefening van jouw (andere) rechten zoals uiteengezet in paragraaf 11. Het videomateriaal wordt
                  maximaal twee weken bewaard, of in het geval van een incident, voor de duur totdat het incident is
                  opgelost.
                </>
              }
            />
            <PrivacyItem title="Reageren op en/of doorlopen van een (open) sollicitatieprocedure:" />
            <PrivacyText
              text={
                <>
                  Wij kunnen jouw Persoonsgegevens(paragraaf 3: A, G, I & P) Verwerken in het geval je ons een (open)
                  sollicitatie stuurt. Voor dit Doeleinde kunnen wij Persoonsgegevens zoals je naam, leeftijd,
                  contactgegevens, CV, cijferlijsten, (eventuele) referentiebrieven, (optionele) diploma&apos;s, een
                  motivatiebrief en/of andere (functie)specifieke informatie Verwerken ten behoeve van het beoordelen
                  van een potentiële werkgelegenheid/tewerkstelling. Voor screeningdoeleinden kunnen wij ook (op de
                  Rechtsgrond van onze ‘Gerechtvaardigd belang) online/openbaar beschikbare informatie zoals
                  sociale-mediaprofielen over jou Verwerken, en de resultaten hiervan gebruiken voor een verdere
                  beoordeling; dit wordt (achteraf) altijd met je besproken/gecommuniceerd indien dit het geval is. De
                  resultaten van de screening zelf zullen nooit de (enige) reden zijn waarop wij sollicitanten afwijzen.
                  In sommige gevallen kunnen wij voor je sollicitatie de hulp inroepen van externe HRadviseurs; in deze
                  gevallen (kunnen) wij (een deel van) jouw Persoonsgegevens met hen delen.
                  <br />
                  <br />
                  De Rechtsgrond waarop wij jouw Persoonsgegevens Verwerken bij een (open) sollicitatie is jouw
                  expliciete &apos;Toestemming&apos;, &apos;Uitvoering van de overeenkomst&apos; (voor zover wij een
                  overeenkomst aangaan) of ‘Gerechtvaardigd belang&apos; (om te beoordelen of wij jou in dienst nemen).
                  Wij bewaren jouw Persoonsgegevens voor bovengenoemde doeleinden niet langer dan noodzakelijk is voor
                  de uitvoering van de sollicitatieprocedure. Indien het sollicitatieproces niet leidt tot
                  indiensttreding bij UNIVERSALES, zullen wij de gegevens niet langer dan acht weken na de
                  sollicitatieprocedure bewaren; dit voor het geval wij je alsnog (willen) benaderen als een andere
                  kandidaat de procedure niet afrondt. In sommige gevallen kunnen wij je vragen om ons Toestemming te
                  geven om jouw Persoonsgegevens voor een langere periode te bewaren. In dat geval bewaren wij jouw
                  gegevens voor de duur van maximaal één jaar na afloop van het sollicitatieproces.
                </>
              }
            />
            <PrivacyItem title="Om overeenkomsten aan te gaan of uit te voeren met Leveranciers, producenten en andere partners waarmee UNIVERSALES zaken heeft of zal doen:" />
            <PrivacyText
              text={
                <>
                  Verschillende Persoonsgegevens (paragraaf 3: A, B & O) kunnen door ons worden Verwerkt bij het aangaan
                  en uitvoeren van de overeenkomsten tussen jou (de leverancier, producent of andere partner) en
                  UNIVERSALES in het kader van het leveren van diensten aan ons en verder kunnen worden gebruikt voor
                  bijvoorbeeld: het onderhouden van contact over en met betrekking tot de tussen ons gesloten
                  overeenkomst, voor facturatie/betalingsdoeleinden, voor accountbeheer (en andere administratieve
                  zaken) alsmede voor het onderhouden van onze zakelijke relatie tussen ons.
                  <br />
                  <br />
                  Wij Verwerken de Persoonsgegevens voor dit specifieke Doeleinde op de Rechtsgrond ‘Uitvoering van de
                  overeenkomst’. Indien wij geen beroep kunnen doen op de Rechtsgrond van Uitvoering van de
                  overeenkomst, kunnen wij de Rechtsgrond van ons ‘Gerechtvaardigd belang’ inroepen, in zoverre ons een
                  beroep op Gerechtvaardigd belang toekomt. Soms kunnen wij de Verwerking van jouw Persoonsgegevens ook
                  baseren op onze ‘Wettelijke verplichting’, of op jouw expliciete ‘Toestemming’. Bovengenoemde
                  Persoonsgegevens worden bewaard zo lang als nodig is om de overeenkomst tussen ons en/of het leveren
                  van onze diensten van of aan jou naar behoren uit te voeren. Daarna verwijderen of Anonimiseren wij de
                  Persoonsgegevens, tenzij wij Wettelijk verplicht zijn (een deel van) deze langer te bewaren of een
                  ander Doeleinde en/of Rechtsgrond hebben. De financiële/fiscale gegevens met betrekking tot onze
                  zakelijke relaties worden bewaard zo lang als nodig is om te voldoen aan onze wettelijke bewaarplicht
                  voor onze (belasting)administratie (minimaal zeven jaar).
                </>
              }
            />
            <PrivacyText
              text={
                <>
                  Daarnaast kunnen we, in overeenstemming met de toepasselijke wetgeving en waar dit gepast wordt
                  geacht, de Rechtsgrond van &apos;Gerechtvaardigd belang&apos; uitoefenen om (een deel van) jouw
                  Persoonsgegevens, of die van een ander individu, te Verwerken voor de volgende Doeleinden:
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title:
                    "Het verzorgen, leveren en ontwikkelen van onze diensten, website(s), apps en/of (content op) andere (sociale media) platforms (zoals Facebook en Instagram);",
                },
                {
                  title:
                    "Om informatie (tijdens onze actieve zakelijke relatie) te communiceren over onze (overige) diensten, promoties, evenementen en wedstrijden waarin je mogelijk geïnteresseerd bent;",
                },
                {
                  title:
                    "Voor communicatie met andere (Derde) partijen en het afhandelen van hun verzoeken (bijvoorbeeld vragen van wettelijke vertegenwoordiger(s));",
                },
                {
                  title: "Om zakelijke/klantrelaties te onderhouden, te verkennen en te laten groeien;",
                },
                {
                  title:
                    "Om informatie te sturen met betrekking tot wijzigingen in onze algemene voorwaarden, de Privacy policy (inclusief Cookies) en/of andere juridische documenten;",
                },
                {
                  title:
                    "Om vervolg e-mails te sturen met andere diensten waarin onze klanten mogelijk geïnteresseerd zijn nadat zij een bestelling hebben geplaatst;",
                },
                {
                  title:
                    "Voor het beoordelen en optimaliseren van procedures met betrekking tot een 'behoefteanalyse' met als Doeleinde de directe klantbenadering en voor klantacquisitie (bijvoorbeeld het analyseren van activiteiten van gebruikers op (onze) sociale media die verband houden met onze diensten, diensten of het bedrijf als geheel);",
                },
                {
                  title:
                    "Voor het uitvoeren van administratie, marketing, planning, product- en serviceontwikkeling en kwaliteitscontrole;",
                },
                {
                  title:
                    "Voor het aanmaken/verzenden van bestellingen, facturen, het vastleggen van transacties, het aanmaken van bestanden en/of het voldoen aan andere administratieve vereisten;",
                },
                {
                  title:
                    "Voor het voorbereiden en voeren van juridische claims/procedures en (het verkrijgen van bewijs met betrekking tot onze (of onze (zaken)partner zijn/haar)) verdediging in geval van juridische geschillen en officiële procedures;",
                },
                {
                  title:
                    "Preventie en onderzoek van strafrechtelijke of contractuele overtredingen en ander wangedrag (bijv. systeem-/bestandsregistratie, cameratoezicht, uitvoeren van interne onderzoeken, gegevensanalyse om fraude en/of andere overtredingen/misdrijven te elimineren);",
                },
                {
                  title:
                    "Ten behoeve van de correcte werking van onze IT-afdeling, website(s), applicaties en andere ITgerelateerde zaken;",
                },
                {
                  title:
                    "Acquisitie en verkoop van bedrijfsonderdelen, bedrijven en andere zakelijke transacties alsmede de Gegevensoverdracht van Persoonsgegevens die daarmee verband houden, dan wel ten behoeve van maatregelen voor de bedrijfsvoering en naleving van Wettelijke en reglementaire verplichtingen en/of het interne reglement van UNIVERSALES;",
                },
                {
                  title:
                    "Alle andere doeleinden die verenigbaar zijn met een 'oorspronkelijk' Doeleinde zoals vermeld in de bovenstaande lijst.",
                },
              ]}
            />
            <PrivacyText
              text={
                <>
                  In het geval dat wij voor een bepaald Doeleinde aanvullende Persoonsgegevens van je nodig hebben,
                  terwijl wij hiervoor geen verenigbaar Doeleinde hebben of kunnen inroepen, of terwijl wij de
                  Verwerking van de specifieke Persoonsgegevens niet vooraf hebben gecommuniceerd, nemen wij contact met
                  je op vanaf het moment dat wij de aanvullende Persoonsgegevens nodig hebben en zullen wij zullen er
                  dan zorg voor dragen dat je de benodigde informatie tot je beschikking hebt zodat je (dan) kunt
                  beslissen of je ons de aanvullende gegevens wilt verstrekken, voor zover wij deze Persoonsgegevens nog
                  niet in ons bezit hebben. In dat geval zullen wij, voor zover nodig, ook uitdrukkelijk jouw
                  Toestemming vragen voordat wij overgaan tot Verwerking van deze aanvullende Persoonsgegevens.
                  <br />
                  <br />
                  Voor zover wij hierboven voor de verschillende Doeleinde geen exacte Bewaartermijn hebben, gegeven
                  voor het Verwerken van de Persoonsgegevens, geldt de volgende norm voor het Bewaartermijn van jouw
                  Persoonsgegevens: wij Verwerken en bewaren jouw Persoonsgegevens zo lang als nodig is voor de
                  Uitvoering van onze overeenkomst, ons Gerechtvaardigd Belang en/of naleving van onze Wettelijke
                  verplichtingen, evenals voor andere Doeleinden die wij nastreven tijdens de Verwerking. Wij bewaren
                  jouw Persoonsgegevens bijvoorbeeld voor de duur van de gehele zakelijke relatie (vanaf het aangaan,
                  tijdens de uitvoering van het contract, voor factureringsdoeleinden alsmede tot de afwikkeling
                  hiervan) als ook na deze duur indien dat in overeenstemming is met ons Gerechtvaardigd belang, jouw
                  Toestemming, de verjaringstermijn van (rechts)acties of documentatie-/administratieverplichtingen,
                  tenzij wij specifiek hierover andere afspraken hebben gemaakt. Persoonsgegevens kunnen bovendien
                  worden bewaard voor de periode waarin vorderingen tegen ons bedrijf kunnen worden ingesteld, voor
                  zover de Persoonsgegevens een bepaald Doeleinde dienen. Bovendien kunnen de Persoonsgegevens worden
                  bewaard voor zover de Gerechtvaardigde belangen van onze zakenpartners/contacten, gelieerde partners
                  en/of andere gelieerde partijen verdere bewaring vereisen (bijvoorbeeld voor bewijs- en
                  documentatiedoeleinden). Zodra Persoonsgegevens niet langer nodig zijn voor een van de bovengenoemde
                  Doeleinde(n) in paragraaf 4 van deze Privacy policy, worden deze Persoonsgegevens zoveel mogelijk
                  verwijderd of geanonimiseerd. Over het algemeen gelden kortere Bewaartermijnen van maximaal twaalf
                  maanden voor operationele gegevens zoals bijvoorbeeld systeemlogboeken, voor zover deze
                  Persoonsgegevens betreffen.
                </>
              }
            />
            <PrivacyBox
              text={
                <>
                  Let op: als je ons ‘Toestemming’ geeft om jouw Persoonsgegevens voor bepaalde Doeleinden te Verwerken
                  (bijvoorbeeld bij het registreren voor het ontvangen van onze nieuwsbrief), zullen wij de
                  Persoonsgegevens alleen Verwerken in het kader van en op basis van deze gegeven Toestemming, tenzij
                  wij een andere Rechtsgrond (en/of Doeleinde) voor de Verwerking hebben, op voorwaarde dat wij die
                  nodig hebben. De door jouw gegeven Toestemming kan op elk moment worden ingetrokken. Dit heeft echter
                  geen invloed op de Persoonsgegevens die zijn Verwerkt voorafgaand aan het intrekken van je
                  Toestemming. Houd er rekening mee dat het intrekken van Toestemming er in sommige gevallen toe kan
                  leiden dat wij bepaalde diensten niet aan je kunnen leveren en/of dat wij de correcte uitvoering van
                  een overeenkomst tussen ons niet (langer) meer kunnen garanderen. Indien je jouw Toestemming wilt
                  intrekken (anders dan voor Cookies), stuur ons dan een e-mail via business@universales.work waarin je
                  uitlegt voor welk Doeleinde en/of Verwerking van jouw Persoonsgegevens de Toestemming wilt intrekken.
                </>
              }
            />
            <PrivacyTitle title="4. Het gebruik van cookies en vergelijkbare technologieën" />
            <PrivacyText
              text={
                <>
                  Wanneer je onze website(s) voor het eerst bezoekt, tonen wij een pop-up bericht waarin het gebruik van
                  onze Cookies wordt toegelicht met een verwijzingslink naar de(ze) Privacy policy (paragraaf 5) waar je
                  alle informatie kunt vinden over de Cookies die wij gebruiken. Voor zover van toepassing hebben wij
                  met andere bedrijven die Cookies plaatsen op onze website(s) afspraken gemaakt over het gebruik van
                  deze Cookies. We hebben echter geen volledige controle over wat de andere bedrijven zelf met de
                  informatie doen. Het is om deze reden aangeraden dat je ook aandacht besteedt aan de Privacy policy
                  van die specifieke partners. UNIVERSALES zal in ieder geval slechts Cookies op je apparaat opslaan die
                  strikt noodzakelijk zijn voor de werking van onze website(s) of ons algemene statistische informatie
                  verstrekken over het gebruik van onze website(s) en/of diensten. Als je Toestemming verleent voor al
                  onze cookies, kunnen wij ook gebruik maken van marketing- /trackingcookies waarmee wij je op maat
                  gemaakte aanbiedingen en advertenties kunnen tonen, hetgeen dat ook op websites van andere
                  organisaties kan gebeuren. Indien jouw identiteit bij ons bekend is, zullen dergelijke bedrijven de
                  identiteit niet van ons te weten komen. Zijzullen alleen weten dat dezelfde gebruiker (ID) die eerder
                  een bepaalde website heeft bezocht, de website opnieuw bezoekt.
                </>
              }
            />
            <PrivacyWarning title="WIJZIG MIJN COOKIE TOESTEMMING" />
            <PrivacyText
              text={
                <>
                  Raadpleeg onze Cookietabel hieronder voor meer gedetailleerde informatie over ons gebruik van Cookies,
                  de Doeleinden waarvoor ze dienen en welke Bewaartermijn wij voor deze Cookies hebben ingesteld tijdens
                  het gebruik ervan.
                </>
              }
            />
            <PrivacyCookie />
            <PrivacyText
              text={
                <>
                  Zoals te zien is in de Cookietabel in paragraaf 5, hebben de meeste Cookies een vooraf ingestelde
                  vervaldatum; ze vervallen automatisch na de ingestelde periode en registreren vanaf dat moment geen
                  gegevens meer. Zie onze Cookietabel voor de specifieke Bewaartermijn voor elk type Cookie. Je kunt er
                  ook voor kiezen om de Cookies voor de vervaldatum handmatig te verwijderen. Als je dit wilt, lees dan
                  de instructies van je browser hoe je dit moet doen.
                </>
              }
            />
            <PrivacyItem title="Knoppen voor sociale media" />
            <PrivacyText
              text={
                <>
                  Daarnaast kunnen wij op onze website(s) gebruik maken van plug-ins (‘buttons’) van sociale (media)
                  netwerken zoals Facebook en Instagram. De buttons werken door middel van stukjes code die afkomstig
                  zijn van de social media provider(s) zelf en zichtbaar zijn voor de websitebezoeker (meestal op basis
                  van de respectievelijke symbolen). We hebben deze elementen zo geconfigureerd dat ze standaard zijn
                  uitgeschakeld. Indien geactiveerd (door erop te klikken en/of door al onze Cookies te accepteren
                  wanneer je (voor het eerst) onze website(s) bezoekt), kunnen de beheerders van de respectieve sociale
                  (media) netwerken Persoonsgegevens zoals IP-adressen vastleggen en/of tracking Cookies plaatsen voor
                  hun eigen Doeleinden. Deze Verwerking van Persoonsgegevens valt onder de verantwoordelijkheid van de
                  respectievelijke leverancier van de plug-ins en vindt plaats in overeenstemming met hun voorschriften
                  voor gegevensbescherming. Wij ontvangen geen informatie over jou van de respectievelijke sociale
                  (media) netwerken. Lees het betreffende privacy policy van respectievelijk Facebook of Instagram (die
                  regelmatig kunnen wijzigen) om erachter te komen hoe zij jouw Persoonsgegevens Verwerken wanneer zij
                  Persoonsgegevens van jou middels deze technieken verzamelen. De informatie die zij Verwerken wordt
                  meestal overgebracht naar en opgeslagen door sociale (media) netwerken op servers in onder meer de
                  Verenigde Staten.
                </>
              }
            />
            <PrivacyTitle title="5. Gegevensoverdracht van Persoonsgegevens buiten Europa (EER)" />
            <PrivacyText
              text={
                <>
                  In het kader van onze bedrijfsactiviteiten, (digitale) diensten en in lijn met de Doeleinden van de
                  gegevensverwerking zoals uiteengezet in paragraaf 4, kunnen wij Persoonsgegevens doorgeven aan andere
                  (Derde) partijen, voor zover een dergelijke Gegevensoverdracht is toegestaan, noodzakelijk is en wij
                  van mening zijn dat gepast is, zodat zij Persoonsgegevens voor ons kunnen Verwerken of, in voorkomend
                  geval, hun eigen Doeleinden. In deze context zijn de meest voorkomende categorieën van Ontvangers:
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title:
                    "Onze dienstverleners (binnen UNIVERSALES of extern, bijvoorbeeld banken, verzekeringen), waaronder Verwerkers en andere IT-aanbieders;",
                },
                {
                  title: "Onze aangesloten partners;",
                },
                {
                  title:
                    "Leveranciers, onderaannemers, zakenpartners (met name in verband met (het leveren van) onze (digitale) diensten en content, alsmede het uitbreiden, ontwikkelen en/of investeren in nieuwe (digitale) diensten of content);",
                },
                {
                  title: "Binnenlandse en buitenlandse autoriteiten of rechtbanken;",
                },
                {
                  title:
                    "De overnemende partij of partijen die geïnteresseerd zijn in de overname van bedrijfsonderdelen, bedrijven of andere onderdelen van de UNIVERSALES;",
                },
                {
                  title: "Andere partijen in een eventuele of lopende juridische procedure;",
                },
                {
                  title:
                    "Andere (Derde) partijen die hierin niet in aanmerking zijn genomen. (Gezamenlijk: “Ontvangers”)",
                },
              ]}
            />
            <PrivacyText
              text={
                <>
                  Het overgrote deel van de Ontvangers van Persoonsgegevens kunnen zich binnen Europa (EER) bevinden (en
                  voor zover van toepassing een geschikte functionaris voor gegevensbescherming hebben aangesteld), maar
                  ze kunnen zich ook in elk land wereldwijd bevinden. Je moet er in het bijzonder op anticiperen dat
                  jouw Persoonsgegevens, in zoverre van toepassing, worden verzonden naar andere landen in Europa (EER)
                  en de VS, afhankelijk van waar onze dienstverleners zich bevinden (zoals Microsoft, Meta enzovoorts).
                  Indien wij Persoonsgegevens doorgeven aan een organisatie buiten Europa (EEA), zorgen wij voor een
                  passend beschermingsniveau zoals Wettelijk verplicht is en sluiten wij met de betreffende organisatie
                  een passende (Verwerkers)overeenkomst. In het geval dat wij (op basis van een vooraf bepaalde
                  Doeleinde) jouw Persoonsgegevens doorgeven aan niet-Europese Verwerkers, zullen wij dit slechts doen
                  op basis van de standaardcontractbepalingen die zijn uitgegeven door de Europese Commissie, bindende
                  bedrijfsregels en met inachtneming van de Rechtsgronden van Toestemming, Uitvoering van de
                  overeenkomst of Gerechtvaardigd belang (zoals het instellen, uitoefenen of handhaven van
                  rechtsvorderingen, openbaar toegankelijke Persoonsgegevens of omdat het noodzakelijk is om de
                  integriteit van de betrokken personen te beschermen), mits wij ervan overtuigd zijn dat voldoende
                  waarborgen en maatregelen in het leven zijn geroepen om de gegevensbescherming te effectueren.
                  <br />
                  <br />
                  Wij verkopen, verhandelen of geven/sturen jouw Persoonsgegevens niet door aan andere (Derde) partijen,
                  tenzij wij jou hiervan vooraf op de hoogte stellen, jouw uitdrukkelijke Toestemming hebben en mits er
                  adequate wettelijke gegevensbescherming wordt gegarandeerd door deze Derde. Dit geldt niet voor
                  website-hostingpartners en andere partijen die ons helpen bij het beheren van onze website(s), het
                  uitvoeren van onze activiteiten of onze dienstverlening, onder de voorwaarde dat deze partijen de AVG
                  naleven en ermee instemmen deze informatie vertrouwelijk te houden. We kunnen jouw Persoonsgegevens
                  ook vrijgeven wanneer wij van mening zijn dat vrijgave hiervan gepast is om te voldoen aan de wet, om
                  ons (website)beleid af te dwingen en/of bijvoorbeeld om de rechten, eigendommen of veiligheid van
                  onszelf of anderen te beschermen. Andere informatie, niet zijnde Persoonsgegevens, kunnen aan andere
                  (Derde) partijen worden verstrekt voor marketing, reclame of voor andere Doeleinden.
                </>
              }
            />
            <PrivacyTitle title="6. Externe aanbieders van onze Cookies en andere (Derde) aanbieders die betrokken zijn bij de product(en) en diensten van UNIVERSALES" />
            <PrivacySubTitle title="Meta Platforms (Facebook)" />
            <PrivacyText
              text={
                <>
                  Wij kunnen gebruik maken van de bezoekersactiepixels (“pixel”) van Meta Platforms, Inc. (1 Hacker Way,
                  Menlo Park, CA 94025, VS, of, als je in de EU gevestigd bent, Meta Platforms Ireland Limited, 4 Grand
                  Canal Square, Grand Canal Harbor, Dublin 2, Ierland (Facebook)). Hierdoor kan het gebruikersgedrag
                  worden gevolgd nadat ze zijn doorgestuurd naar de website van de aanbieder door op een
                  Facebook-advertentie te klikken. Dit stelt ons in staat om de effectiviteit van Facebook-advertenties
                  te meten voor statistische en marktonderzoeksdoeleinden. De gegevens die op deze manier worden
                  verzameld, zijn voor ons anoniem, d.w.z. we zien de persoonlijke gegevens van individuele gebruikers
                  niet. Deze gegevens worden echter door Facebook opgeslagen en verwerkt en daarom informeren wij jou
                  hierover. Facebook kan deze informatie koppelen aan uw Facebookaccount en deze ook gebruiken voor
                  eigen promotionele doeleinden, in overeenstemming met het gegevensgebruiksbeleid van Facebook
                  https://www.facebook.com/about/privacy/. Je kunt Facebook en zijn partners toestaan advertenties op en
                  buiten Facebook te plaatsen. Voor deze doeleinden kan ook een cookie op jouw computer worden
                  opgeslagen. De wettelijke basis voor het gebruik van deze dienst is Toestemming. U kunt bezwaar maken
                  tegen het verzamelen van uw gegevens door Facebookpixel of tegen het gebruik van uw gegevens voor het
                  weergeven van Facebook-advertenties door contact op te nemen met het volgende adres:
                  https://www.facebook.com/settings?tab=ads. Wij hebben er verder alles aan gedaan om de geschiktheid
                  van de gegevensoverdracht naar het derde land van de VS door de overeenkomst van
                  EU-modelcontractbepalingen te garanderen.
                </>
              }
            />
            <PrivacySubTitle title="Vercel Inc." />
            <PrivacyText
              text={
                <>
                  Onze website en e-maildiensten worden gehost via Vercel Inc. (Vercel.com). Vercel Inc. Verwerkt namens
                  ons Persoonsgegevens van onze website(s) en gebruikt jouw gegevens niet voor hun eigen Doeleinden.
                  Deze (Derde) partij kan wel metadata verzamelen over het gebruik van de diensten, dit zijn geen
                  Persoonsgegevens. Vercel Inc. heeft passende technische en organisatorische maatregelen genomen om
                  verlies en ongeoorloofd gebruik van jouw Persoonsgegevenste voorkomen. Met Vercel Inc. hebben wij een
                  Verwerkersovereenkomst gesloten waarin de verantwoordelijkheden en verplichtingen van beide partijen
                  duidelijk zijn vastgelegd. Vercel Inc. is op grond van de 16/18 overeenkomst tot geheimhouding
                  verplicht. Lees meer informatie over het Privacy policy van Vercel Inc.
                  https://vercel.com/legal/privacy-policy#eea.
                </>
              }
            />
            <PrivacyTitle title="7. Gegevensbeveiliging" />
            <PrivacyText
              text={
                <>
                  Wij hebben passende technische en organisatorische beveiligingsmaatregelen genomen om jouw
                  Persoonsgegevens betrouwbaar te beschermen tegen ongeoorloofde toegang, verlies, vernietiging,
                  vervorming, manipulatie, ongeoorloofd kopiëren, ongeoorloofde toegang en/of andere inbreuken op de
                  Verwerking van gegevens in overeenstemming met &apos;Privacy by design&apos; en &apos;Privacy by
                  default&apos;.
                  <br />
                  <br />
                  UNIVERSALES heeft verschillende interne beleidsregels zoals een Datalekprotocol, een
                  Verwerkingsregister, een Cameraprotocol en bijvoorbeeld duidelijke privacyrichtlijnen met of voor
                  dienstverleners en/of andere betrokken (Derde) partijen. Het personeel van UNIVERSALES wordt verder
                  regelmatig op de hoogte gehouden van de laatste privacy ontwikkelingen en/of wetgeving. <br />
                  <br />
                  Wat betreft technische maatregelen heeft UNIVERSALES ervoor gezorgd dat IT- en
                  netwerkbeveiligingsoplossingen aanwezig zijn zoals toegangscontrole en restricties
                  (wachtwoordbeveiliging van onze IT-systemen), fysieke toegangsmaatregelen en organisatorische
                  maatregelen voor toegangsbeveiliging, beveiliging van netwerkverbindingen via Transport Layer Security
                  (TLS) technologie, encryptie van gegevens(dragers) en transmissies, Pseudonimisering en/of
                  Anonimisering van Persoonsgegevens, regelmatige inspecties, kwaliteitswaarborging en andere technische
                  en organisatorische (beveiligings)maatregelen bij Verwerking van jouw Persoonsgegevens. Zie ook de
                  overige (privacy) documentatie van UNIVERSALES voor meer specifieke informatie ten aanzien van onze
                  technische en organisatorische maatregelen.
                </>
              }
            />
            <PrivacyTitle title="8. Website(s) van Derden" />
            <PrivacyText
              text={
                <>
                  Houd er rekening mee dat deze Privacy policy en Cookie-gedeelte niet van toepassing zijn op andere
                  (Derde) websites die door middel van hyperlinks naar of van onze website zijn gelinkt. Wij hebben geen
                  overzicht of invloed en kunnen daarom niet garanderen dat deze andere (Derde) partijen op een
                  betrouwbare, veilige manier en in overeenstemming met de AVG met jouw Persoonsgegevens omgaan. Lees de
                  privacy policy van deze andere (Derde) partijen voordat je jouw Persoonsgegevens met hen deelt.
                </>
              }
            />
            <PrivacyTitle
              title="9. Als je geen Persoonsgegevens aan ons wilt verstrekken in het kader van onze zakelijke relatie
"
            />
            <PrivacyText
              text={
                <>
                  In het kader van onze zakelijke relatie vragen wij jou om ons Persoonsgegevens te verstrekken die
                  nodig zijn voor het aangaan en uitvoeren van onze zakelijke relatie en het nakomen van onze
                  contractuele/Wettelijke verplichtingen. In de regel bestaat er echter geen (Wettelijke) verplichting
                  om ons jouw Persoonsgegevens te verstrekken. Houd er echter rekening mee dat wij zonder bepaalde
                  informatie meestal geen overeenkomst met jou (of de entiteit of persoon die je vertegenwoordigt)
                  kunnen aangaan of uitvoeren.
                </>
              }
            />
            <PrivacyTitle title="10. Profilering en geautomatiseerde individuele besluitvorming" />
            <PrivacyText
              text={
                <>
                  Bij het aangaan en tijdens het uitvoeren van een zakelijke relatie of tijdens het gebruik van onze
                  website(s) maken wij in het algemeen geen gebruik van geautomatiseerde individuele besluitvorming
                  (zoals bedoeld in artikel 22 van de AVG). Mochten wij besluiten om dergelijke methoden te gebruiken,
                  dan zullen wij je hierover uiteraard afzonderlijk informeren alsmede over de relevante rechten die jou
                  toekomen, voor zover dat wettelijk vereist is.
                  <br />
                  <br />
                  Wel kunnen wij je Persoonsgegevens gedeeltelijk automatisch Verwerken met als Doeleinde bepaalde
                  persoonlijke aspecten te evalueren (Profiling). Profilering stelt ons in het bijzonder in staat je
                  nauwkeuriger te informeren en te adviseren over voor jou mogelijk relevante diensten. Voor dit
                  Doeleinde kunnen wij evaluatietools gebruiken waarmee wij met je kunnen communiceren en kunnen
                  adverteren, inclusief markt- en opinieonderzoek (zie ook paragraaf 5 & 6 van deze Privacy policy).
                </>
              }
            />
            <PrivacyTitle title="11. Jouw rechten onder de AVG" />
            <PrivacyText
              text={
                <>
                  In overeenstemming met- en voor zover voorzien in de AVG heb je verschillende rechten ten aanzien van
                  de Verwerking van jouw Persoonsgegevens door UNIVERSALES In het uitzonderlijke geval dat het
                  uitoefenen van bepaalde rechten kosten met zich meebrengt, zullen wij je hiervan vooraf op de hoogte
                  stellen. Meer specifiek, naast de mogelijkheid om Toestemming in te trekken, zoals uitgelegd in
                  paragraaf 4 van deze Privacy policy, hebt je ook de volgende rechten:
                </>
              }
            />
            <PrivacyList
              list={[
                {
                  title: "Recht op toegang (Art. 15 AVG)",
                  text: (
                    <>
                      Dit recht geeft de Betrokkene inzicht in de hem of haar betreffende Persoonsgegevens en in andere
                      belangrijke informatie zoals de Doeleinden van de Verwerking of de periode waarvoor de gegevens
                      worden bewaard;
                    </>
                  ),
                },
                {
                  title: "Recht op rectificatie (Art. 16 AVG)",
                  text: (
                    <>
                      Dit recht geeft de Betrokkene de mogelijkheid om onjuiste Persoonsgegevens die op hem/haar
                      betrekking hebben te laten corrigeren;
                    </>
                  ),
                },
                {
                  title: "Recht op gegevenswissing (Art. 17 AVG)",
                  text: (
                    <>
                      Dit recht geeft de Betrokkenen de mogelijkheid om Persoonsgegevens in bezit van
                      Verwerker/Verwerkingsverantwoordelijke te laten wissen onder de voorwaarden zoals uiteengezet in
                      art. 17 van de AVG;
                    </>
                  ),
                },
                {
                  title: "Recht op beperking van de Verwerking (Art. 18 AVG)",
                  text: (
                    <>
                      Dit recht geeft de Betrokkene de mogelijkheid om (tijdelijk) verdere Verwerking van
                      Persoonsgegevens betreffende de Betrokkene te verbieden. Een beperking van de Verwerking van
                      Persoonsgegevens doet zich voornamelijk voor in het stadium dat de Betrokkene onderzoek doet naar
                      de uitoefening van andere aan hem/haar toekomende rechten;
                    </>
                  ),
                },
                {
                  title: "Recht op gegevensoverdraagbaarheid (Art. 20 AVG)",
                  text: (
                    <>
                      Dit recht geeft de mogelijkheid voor Betrokkenen om hun Persoonsgegevens (na het indienen van een
                      verzoek) van Verwerker/Verwerkingsverantwoordelijke in een gangbaar, machine leesbaar formaat te
                      ontvangen en deze gegevens te laten overdragen aan een andere Verwerkingsverantwoordelijke of
                      Verwerker;
                    </>
                  ),
                },
                {
                  title: "Recht van bezwaar (Art. 21 AVG)",
                  text: (
                    <>
                      Dit recht omvat de mogelijkheid voor Betrokkenen om bezwaar te maken tegen de verdere Verwerking
                      van hun Persoonsgegevens. Houd er rekening mee dat wij ons het recht voorbehouden om wettelijke
                      beperkingen van onze kant af te dwingen, bijvoorbeeld als wij verplicht zijn om bepaalde gegevens
                      te bewaren of te Verwerken, een zwaarwegend belang hebben (voor zover wij dergelijke belangen
                      mogen inroepen) of de Persoonsgegevens nodig hebben voor het doen gelden van (rechts)vorderingen.
                    </>
                  ),
                },
              ]}
            />
            <PrivacyText
              text={
                <>
                  In het algemeen vereist het uitoefenen van deze rechten dat je jouw identiteit kunt bewijzen. In
                  sommige gevallen kunnen wij jou vragen om je te identificeren zodat voorkomen wordt dat wij jou, of
                  andere, onjuiste Persoonsgegevens toesturen. Om deze rechten te doen gelden, verzoeken wij om contact
                  met ons op te nemen via de contactgegevens zoals vermeld in paragraaf 1. We proberen zo snel mogelijk,
                  maar uiterlijk één maand na het versturen van je bericht, op je verzoek, vraag of klacht terug te
                  komen. Indien je een klacht hebt over de (wijze van) Verwerking van jouw Persoonsgegevens, staan wij
                  altijd tot je beschikking om een juist antwoord/oplossing te vinden op elke vraag of opmerking met
                  betrekking tot jouw privacy. Mocht je er desondanks niet uitkomen met ons, dan willen wij je erop
                  wijzen dat je altijd de mogelijkheid hebt om een klacht in te dienen bij de Autoriteit
                  Persoonsgegevens (zie hieronder).
                </>
              }
            />
            <PrivacyItem title="Klachten en de Toezichthoudende autoriteiten" />
            <PrivacyText
              text={
                <>
                  Hoewel wij te allen tijde tot je beschikking staan voor alle privacy gerelateerde zaken, heeft elke
                  Betrokkene het recht om zijn/haar rechten bij de rechtbank af te dwingen of een klacht in te dienen
                  bij de bevoegde Toezichthoudende autoriteit.
                  <br />
                  <br />- De bevoegde Toezichthoudende autoriteit van Nederland is de Autoriteit Persoonsgegevens (
                  <button
                    onClick={(e) => openLink(e, "https://www.autoriteitPersoonsgegevens.nl")}
                    className="text-blue text-bold"
                  >
                    https://www.autoriteitPersoonsgegevens.nl
                  </button>
                  ).
                </>
              }
            />
            <PrivacyTitle title="12. Wijzigingen van deze Privacy policy" />
            <PrivacyText
              text={
                <>
                  We kunnen deze Privacy policy op elk moment wijzigen zonder voorafgaande kennisgeving. De versie die
                  op onze website is gepubliceerd, is de meest actuele versie en is van toepassing op alle relaties
                  tussen UNIVERSALES en de Betrokkene(n). Indien de Privacy policy onderdeel uitmaakt van een
                  overeenkomst met jou, zullen wij je per e-mail of op een andere passende wijze op de hoogte stellen
                  van een belangrijke wijziging van de Privacy policy. Wanneer er belangrijke wijzigingen zijn
                  aangebracht in de huidige versie van de Privacy policy, zullen wij je hiervan (direct) op de hoogte
                  stellen door een bericht op onze startpagina te publiceren. Om jezelf op de hoogte te houden van de
                  laatste versie van ons Privacy policy, word je daarom aangeraden dit document (via de website)
                  regelmatig te raadplegen.
                </>
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Privacy
