export const SERVER = "https://api.universales.work"

export const URL = {
  HOME: "/",
  ABOUT: "/overons",
  ADVERTISING: "/adverteerders",
  CONTACT: "/contact",
  CONTENT: "/platform",
  EVOLUTION: "/evolution",
  PRIVACY: "/privacy",
  REGISTER: "/#register",
}

export const REQ = {
  HEALTH: SERVER + "/health",
  FORM: SERVER + "/mail",
}

export const SOCIAL = {
  FACEBOOK: "",
  INSTAGRAM: "https://www.instagram.com/universales.work",
  LINKEDIN: "https://www.linkedin.com/in/universales-recruitment-068030218/",
}

export const FORM = {
  URL: "https://docs.google.com/forms/d/e/1FAIpQLSfwxtmYTA6r0PEs4Fl4fDVwaqmEDI0mrs_luJ5snh1MXYF8cQ/viewform",
}

export const MAP = {
  ANKERSMITPLEIN:
    "https://www.google.nl/maps/place/Ankersmidplein+2,+1506+CK+Zaandam/@52.4391448,4.8139041,16z/data=!4m5!3m4!1s0x47c5fcd229986ab3:0x5a00431487140578!8m2!3d52.4387265!4d4.8173212",
}
