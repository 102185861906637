import React from "react";
import { MdCheck } from "react-icons/md";

const CheckList = ({ options, value, handler }: { options: string[]; value: string; handler: any }) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-16">
        {options.map((option: string, key: number) => (
          <button
            onClick={() => handler(option)}
            className="w-full grid grid-cols-[auto,1fr] gap-16 items-center"
            key={key}
          >
            {option === value ? (
              <div className="w-24 h-24 rounded-4 bg-gradient-to-tr from-purple to-blue flex items-center justify-center">
                <MdCheck className="text-16 text-white" />
              </div>
            ) : (
              <div className="w-24 h-24 rounded-4 bg-grey-border"></div>
            )}
            <div className="w-full text-left text-16 font-semibold text-white">{option}</div>
          </button>
        ))}
      </div>
    </>
  );
};

export default CheckList;
