import { parseMinutes } from "libs/functions"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { ImVolumeMute, ImVolumeMute2 } from "react-icons/im"
import { IoMdPause } from "react-icons/io"
import { MdFullscreen, MdPlayArrow, MdPlayCircleFilled } from "react-icons/md"
import ReactPlayer from "react-player"
import Wrapper from "utils/Wrapper"

const VideoPlayer = ({
  video,
  light,
  loop,
  title,
  length,
  controls,
  maxWidth,
}: {
  video: string
  light: boolean
  loop?: boolean
  title?: string
  length?: string
  controls: boolean
  maxWidth?: number
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const player = useRef<ReactPlayer>(null)

  const [dimensions, setDimensions] = useState([0, 0])

  const [playsInline] = useState(true)

  const [width, setWidth] = useState("100%")
  const [height, setHeight] = useState("0px")
  const [volume] = useState(1)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)

  const [played, setPlayed] = useState(0)
  const [playedPercentage, setPlayedPercentage] = useState(0)
  const [duration, setDuration] = useState(0)

  const [seeking, setSeeking] = useState(false)

  useEffect(() => {
    setSize()
    document.addEventListener("keydown", (e) => escFunction(e), false)
  }, [])

  useEffect(() => {
    setSize()
  }, [fullscreen, dimensions, width, height])

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  function escFunction(e: any) {
    if (e.keyCode === 27) {
      setFullscreen(false)
    }
  }

  function updateSize() {
    setDimensions([window.innerWidth, window.innerHeight])
  }

  function setSize() {
    if (ref.current) {
      const heightMax = dimensions[1] - 100
      const wide = dimensions[0] * 9 > heightMax * 16

      const heightNormal = (ref.current.clientWidth * 9) / 16

      const heightFullscreen = wide ? heightMax : (ref.current.clientWidth / 16) * 9
      const widthFullscreen = wide ? (ref.current?.clientHeight / 9) * 16 : dimensions[0]

      setHeight(`${fullscreen ? heightFullscreen : heightNormal}px`)
      setWidth(fullscreen ? `${widthFullscreen}px` : "100%")
    }
  }

  const playIcon = (
    <div className="z-30 flex items-center justify-center bg-purple">
      <div className="w-full absolute top-0 left-0 bottom-0 bg-gradient-to-r from-purple to-blue"></div>
      <div className="w-full flex items-center justify-center">
        <div className="w-40 h-40 shadow-md rounded-full relative">
          <MdPlayCircleFilled className="absolute -top-4 -left-4 text-48 text-white" />
        </div>
        <Wrapper open={title ? true : false}>
          <div className="text-white font-bold text-14 absolute top-10 md:top-18 left-10 md:left-18">{title}</div>
        </Wrapper>
        <Wrapper open={length ? true : false}>
          <div className="text-white opacity-40 font-bold text-14 absolute bottom-10 md:bottom-18 left-10 md:left-18">
            {length}
          </div>
        </Wrapper>
      </div>
    </div>
  )

  function onDuration(state: any) {
    setDuration(state)
  }

  function onProgress(state: any) {
    if (!seeking) {
      setPlayed(state.playedSeconds)
      setPlayedPercentage(state.played)
    }
  }

  function onSeekMouseDown() {
    setSeeking(true)
  }

  function onSeekMouseUp(e: any) {
    setSeeking(false)

    if (player.current) {
      player.current.seekTo(parseFloat(e.target.value))
    }
  }

  function onSeekChange(e: any) {
    setPlayedPercentage(e.target.value)
  }

  return (
    <>
      <div
        className={` light-r ${
          fullscreen
            ? "z-50 flex py-50 items-center justify-center bg-black fixed top-0 left-0 w-screen h-screen"
            : "w-full h-full"
        }`}
      >
        <div
          ref={ref}
          style={{ width: width, height: height }}
          className={`${
            maxWidth
              ? "absolute top-[150px] left-[50%] transform translate-x-[-50%] translate-y-[-50%] overflow-hidden !h-[600px]"
              : "relative rounded-4 overflow-hidden bg-black light-r"
          }`}
        >
          <ReactPlayer
            ref={player}
            url={video}
            playing={playing}
            light={light}
            volume={volume}
            muted={muted}
            loop={loop}
            playIcon={playIcon}
            controls={false}
            width={maxWidth ? `${maxWidth}px` : width}
            height={maxWidth ? "" : height}
            playsinline={playsInline}
            progressInterval={100}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
            onReady={() => setPlaying(true)}
            onDuration={(state) => onDuration(state)}
            onProgress={(state) => onProgress(state)}
          />
          <Wrapper open={controls}>
            <div className="absolute z-20 left-0 bottom-0 w-full h-full opacity-0 hover:opacity-100">
              <div className="absolute w-full left-0 bottom-0 h-80 bg-gradient-to-t from-grey-40 to-transparent"></div>
              <div className="absolute left-0 bottom-0 w-full p-10 h-50 grid grid-cols-[auto,1fr,auto,auto,auto] items-center gap-10">
                <button
                  onClick={() => setPlaying(!playing)}
                  className="w-30 h-30 rounded-4 group hover:bg-white-10 flex items-center justify-center"
                >
                  {playing ? (
                    <IoMdPause className="text-white group-hover:text-purple text-20" />
                  ) : (
                    <MdPlayArrow className="text-white group-hover:text-purple text-28" />
                  )}
                </button>
                <div className="w-full flex flex-wrap gap-4 items-center relative group">
                  <div className="w-full bg-white bg-opacity-20 h-4 flex items-center justify-start">
                    <div
                      className="bg-white group-hover:bg-purple h-4"
                      style={{ width: `${playedPercentage * 100}%` }}
                    ></div>
                  </div>
                  <input
                    type="range"
                    min={0}
                    max={0.999999}
                    step="any"
                    value={playedPercentage}
                    onMouseDown={onSeekMouseDown}
                    onChange={(e) => onSeekChange(e)}
                    onMouseUp={(e) => onSeekMouseUp(e)}
                    className="top-[50%] translate-y-[-50%] w-[calc(100%+18px)] mx-[-9px] player-seek"
                  />
                </div>
                <div className="cursor-pointer text-12 font-semibold text-white hover:opacity-100 opacity-60">
                  {parseMinutes(played)} / {parseMinutes(duration)}
                </div>
                <button
                  onClick={() => setMuted(!muted)}
                  className="w-30 h-30 rounded-4 group hover:bg-white-10 flex items-center justify-center"
                >
                  {muted ? (
                    <ImVolumeMute2 className="text-white group-hover:text-purple text-20" />
                  ) : (
                    <ImVolumeMute className="text-white group-hover:text-purple text-20" />
                  )}
                </button>
                <button
                  onClick={() => setFullscreen(!fullscreen)}
                  className="w-30 h-30 rounded-4 group hover:bg-white-10 flex items-center justify-center"
                >
                  <MdFullscreen className="text-white group-hover:text-purple text-24" />
                </button>
              </div>
            </div>
          </Wrapper>
        </div>
        <Wrapper open={fullscreen}>
          <button
            onClick={() => setFullscreen(false)}
            className="absolute bottom-0 left-0 w-full p-20 flex items-center justify-center h-50 gap-10"
          >
            <div className="text-grey">Press</div>
            <div className="bg-white-10 px-8 h-24 rounded-4 text-white text-14 font-semibold flex items-center">
              ESC
            </div>
            <div className="text-grey">to exit fullscreen mode</div>
          </button>
        </Wrapper>
      </div>
    </>
  )
}

export default VideoPlayer
