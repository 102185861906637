import ContentCareer from "components/content/ContentCareer"
import ContentSlider from "components/content/ContentSlider"
import ContentTop from "components/content/ContentTop"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import React from "react"
import VideoPlayer from "utils/VideoPlayer"

const Content = () => {
  return (
    <>
      <Nav />
      <div className="w-full flex items-center justify-center px-20 sm:px-30 relative z-20">
        <div className="w-full max-w-screen-xl">
          <div className="relative w-full h-[450px] mb-[80px] md:mb-40 z-20 flex items-center">
            <div className="absolute top-[-10px] w-[1550px] h-[450px] left-[50%] transform translate-x-[-50%]">
              <VideoPlayer
                video={
                  "https://vid.cdn-website.com/25419e1d/videos/MRhHIxhKR5mI11pBsOxn_32659_group_of_people_looking_straight_at_camera_and_posing_in_concrete_hallway_by_MXR_Productions_Artgrid-HD_H264-HD-v.mp4"
                }
                light={false}
                loop={true}
                controls={false}
                maxWidth={1550}
              />
              <div className="absolute z-20 w-screen transform translate-x-[-50%] left-[50%] bottom-[-1px] h-[150px] bg-gradient-to-b from-transparent to-background"></div>
              <div className="absolute z-20 w-[150px] left-[-1px] bottom-0 top-0 h-full bg-gradient-to-l from-transparent to-background"></div>
              <div className="absolute z-20 w-[150px] right-[-1px] bottom-0 top-0 h-full bg-gradient-to-r from-transparent to-background"></div>
            </div>
            <div className="w-full text-shadow relative text-center mt-20 text-white font-bold text-36 sm:text-48 md:text-72 leading-[40px] sm:leading-[50px] md:leading-[80px]">
              UNIVERSALES <br /> PLATFORM
            </div>
          </div>
          <div className="w-full my-40 md:my-[140px]">
            <ContentTop />
          </div>
          <div className="w-full mb-40 md:mb-[140px] relative">
            <ContentCareer />
          </div>
          <div className="w-full mb-40 md:mb-[140px] relative">
            <ContentSlider />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Content
