import React from "react"

const PrivacyCookie = () => {
  return (
    <>
      <div className="w-full overflow-x-scroll">
        <div className="w-max min-w-full grid grid-cols-1 gap-8">
          <div className="w-max min-w-full flex items-center justify-between gap-20">
            <div className="w-[130px] text-white-60 text-14">Naam van Cookie</div>
            <div className="w-[130px] text-white-60 text-14">Leverancier</div>
            <div className="w-[340px] text-white-60 text-14">Doeleinde / Categorieën van Persoonsgegevens</div>
            <div className="w-[130px] text-white-60 text-14">Bewaar termijn</div>
            <div className="w-[90px] text-white-60 text-14 text-right">Type Cookie</div>
          </div>
          <div className="w-max min-w-full grid grid-cols-1 gap-12">
            <div className="w-max min-w-full flex items-center justify-between gap-20">
              <div className="w-[130px] text-white text-16 font-sembold">CookieConsent (First party)</div>
              <div className="w-[130px] text-white text-16 font-sembold">UNIVERSALES</div>
              <div className="w-[340px] text-white text-16 font-sembold">
                Slaat de cookietoestemmingsstatus van de gebruiker op voor het huidige domein
              </div>
              <div className="w-[130px] text-white text-16 font-sembold">Max. 31 dagen</div>
              <div className="w-[90px] text-white text-16 font-sembold text-right">HTML</div>
            </div>
            <div className="w-max min-w-full flex items-center justify-between gap-20">
              <div className="w-[130px] text-white text-16 font-sembold">_fbp</div>
              <div className="w-[130px] text-white text-16 font-sembold">Facebook (meta)</div>
              <div className="w-[340px] text-white text-16 font-sembold">
                Deze __fbp-pixel wordt door Facebook gebruikt om een reeks advertentieproducten te leveren, zoals
                realtime bieden van externe adverteerders. In het algemeen verwerkt de pixel informatie uit HTTPheaders
                (bijv. IP), Pixelspecifieke gegevens (bijv. Pixel-ID en de cookie), Klikgegevens op knoppen, optionele
                waarden (bijv. conversatiewaarde, paginatype enz.), Formulierveldnamen (bijv. e-mail, adres, aantal
                enz.).
              </div>
              <div className="w-[130px] text-white text-16 font-sembold">Max. 3 maande n</div>
              <div className="w-[90px] text-white text-16 font-sembold text-right">HTTP</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyCookie
