import React from "react";

const Area = ({
  title,
  value,
  handler,
}: {
  title: string;
  value: string;
  handler: any;
}) => {
  return (
    <>
      <div className="w-full bg-grey-border bg-opacity-40 rounded-6 flex items-start p-20">
        <textarea
          className="w-full text-14 h-100 md:text-16 resize-none font-semibold placeholder-grey:placeholder text-white"
          placeholder={title}
          value={value}
          onChange={(e) => handler(e.target.value)}
        ></textarea>
      </div>
    </>
  );
};

export default Area;
