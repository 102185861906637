import React from "react";

const ContentSubList = ({ ordered, list }: { ordered?: boolean; list: JSX.Element[] }) => {
  return (
    <>
      {ordered ? (
        <ol className="w-full text-grey-text text-14 md:text-16 list-decimal pl-24">
          {list.map((item: JSX.Element) => item)}
        </ol>
      ) : (
        <ul className="w-full text-grey-text text-14 md:text-16 list-disc pl-24">
          {list.map((item: JSX.Element) => item)}
        </ul>
      )}
    </>
  );
};

export default ContentSubList;
