import Banner from "assets/img/banner_evolution.png"
import ShadeTop from "assets/img/shade_top.png"
import EvolutionAbout from "components/evolution/EvolutionAbout"
import EvolutionPerks from "components/evolution/EvolutionPerks"
import EvolutionSlides from "components/evolution/EvolutionSlides"
import EvolutionTop from "components/evolution/EvolutionTop"
import Footer from "components/footer/Footer"
import HomeRegister from "components/home/HomeRegister"
import Nav from "components/nav/Nav"
import React from "react"

const Evolution = () => {
  return (
    <>
      <Nav />
      <div className="w-full flex items-center justify-center px-20 sm:px-30 relative z-20">
        <div className="w-full max-w-screen-2xl">
          <div className="relative w-full h-[600px] z-20 flex items-center">
            <div className="absolute top-0 w-max h-[600px] left-[50%] transform translate-x-[-50%]">
              <img src={Banner} alt="" className="min-h-full" />
            </div>
            <div className="absolute top-[-600px] left-[50%] transform translate-x-[-50%] w-max">
              <img src={ShadeTop} alt="" className="" />
            </div>
            <div className="w-full relative text-center text-white font-bold text-36 sm:text-48 md:text-72 leading-[40px] sm:leading-[50px] md:leading-[80px]">
              UNIVERSALES <br /> PLATFORM
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-80 sm:gap-100 xl:gap-[120px] py-60 sm:py-80 md:py-100">
            <EvolutionTop />
            <EvolutionAbout />
            <EvolutionSlides />
            <EvolutionPerks />
            <HomeRegister />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Evolution
