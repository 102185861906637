import { ReactComponent as Dotted } from "assets/img/dotted.svg"
import Slide5 from "assets/img/slider/candy_fruit.jpg"
import Slide2 from "assets/img/slider/friday_drinks.jpg"
import Slide0 from "assets/img/slider/incentive_trips.jpg"
import Slide3 from "assets/img/slider/learning_and_development.jpg"
import Slide4 from "assets/img/slider/lunchclub.jpg"
import Slide1 from "assets/img/slider/parties.jpg"
import { SlideInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"

const ContentSlider = () => {
  const [artists] = useState([
    { image: Slide0, name: "Incentive Trips" },
    { image: Slide1, name: "Quarter Party" },
    { image: Slide2, name: "Friday Drinks" },
    { image: Slide3, name: "Learning and Development" },
    { image: Slide4, name: "Lunchclub" },
    { image: Slide5, name: "Candy, Fruit & Smoothies" },
  ])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const [width] = useState(500 + 40)
  const [base] = useState(-2 * width * artists.length - 250)

  const [offset, setOffset] = useState(base)
  const [block] = useState(width * artists.length * 5 - 20)

  const [active, setActive] = useState(0)
  const [scale] = useState(windowDimensions.width / 700)

  const [usedArrow, setUsedArrow] = useState(false)

  const [slider] = useState(artists.concat(artists.concat(artists.concat(artists.concat(artists)))))

  useEffect(() => {
    window.addEventListener("resize", onResize)

    onResize()
    onTimer()
  }, [])

  useEffect(() => {
    setOffset(base - active * width)
  }, [active])

  function onResize() {
    setWindowDimensions(getWindowDimensions())
  }

  function onTimer() {
    const time = window.setInterval(() => {
      if (!usedArrow) {
        setActive((act) => (act + 1) % artists.length)
      }
    }, 5000)

    return () => {
      window.clearInterval(time)
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  function slideNext() {
    setActive((act) => (act + 1) % artists.length)

    setUsed()
  }

  function slidePrevious() {
    setActive((act) => (act - 1) % artists.length)

    setUsed()
  }

  function setUsed() {
    setUsedArrow(true)
    setInterval(() => {
      setUsedArrow(false)
    }, 60000)
  }

  return (
    <>
      <div className="w-full mb-40 md:mb-[110px] flex flex-wrap gap-20 md:gap-40 items-center justify-between">
        <div className="w-[580px] max-w-full flex flex-wrap justify-start gap-20 md:gap-30">
          <div className="w-full text-white text-32 sm:text-48 md:text-64 font-bold leading-[40px] sm:leading-[50px] md:leading-[72px]">
            De start van jouw <br />
            Sales carrière
          </div>
          <div className="w-full flex items-center justify-start -mx-22">
            <Dotted />
          </div>
        </div>
        <div className="flex items-center justify-center gap-30">
          <button
            onClick={() => slidePrevious()}
            className="w-70 h-70 hover:bg-grey rounded-full border-2 border-white flex items-center justify-center"
          >
            <MdKeyboardArrowLeft className="text-20 text-white" />
          </button>
          <button
            onClick={() => slideNext()}
            className="w-70 h-70 hover:bg-grey rounded-full border-2 border-white flex items-center justify-center"
          >
            <MdKeyboardArrowRight className="text-20 text-white" />
          </button>
        </div>
      </div>
      <div
        style={{
          transform: `scale(${scale < 1 ? scale : ""})`,
          marginTop: `${scale < 1 ? `-${(1 - scale) * 300}` : ""}px`,
          marginBottom: `${scale < 1 ? `-${(1 - scale) * 300}` : ""}px`,
        }}
        className="w-full h-[500px] relative transform"
      >
        <div
          style={{ width: `${block}px`, transform: `translateX(${offset}px)` }}
          className="absolute w-0 transition duration-500 h-[500px] top-0 left-[50%] gap-40 flex"
        >
          {slider.map((artist: SlideInterface, key: number) => (
            <button
              className={`w-[500px] h-[500px] relative overflow-hidden rounded-[16px] ${
                key === 2 * artists.length + active ? "" : "opacity-60"
              }`}
              key={key}
            >
              <img
                src={artist.image}
                alt=""
                className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-full"
              />
              {key === 2 * artists.length + active ? (
                <div className="absolute z-10 bottom-0 left-0 h-100 w-full flex items-center justify-center p-20 text-white text-24 font-bold bg-grey-shade bg-opacity-50">
                  {artist.name}
                </div>
              ) : (
                ""
              )}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}

export default ContentSlider
