import { BenefitInterface } from "libs/interfaces"
import React, { useState } from "react"
import { BiWorld } from "react-icons/bi"
import { FaUser } from "react-icons/fa"
import { GiStarsStack } from "react-icons/gi"

const HomeBenefits = () => {
  const [benefits] = useState([
    {
      title: "Sales Agent",
      text: "Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een hoge motivatie en doorzettingsvermogen. Begin als Sales Agent bij één van de aangesloten verkooporganisaties en start met het maken van sales.",
      icon: (
        <div className="w-80 h-80 relative">
          {/* <div className="absolute top-0 left-0 w-80 h-80 rounded-full bg-blue-light bg-opacity-50 filter blur-[80px]"></div> */}
          <div className="w-80 h-80 rounded-full bg-blue-light bg-opacity-50 flex items-center justify-center">
            <FaUser className="text-blue-light text-30" />
          </div>
        </div>
      ),
    },
    {
      title: "Sales Professional",
      text: "Beschik je over relevante werkervaring of heb je een commerciële Hbo-opleiding succesvol afgerond; dan ben je klaar voor de volgende stap in jouw sales carrière. Maak gebruik van het UNIVERSALES netwerk en samen vinden we de opdrachtgever die bij jou past.",
      icon: (
        <div className="w-80 h-80 relative">
          {/* <div className="absolute top-0 left-0 w-80 h-80 rounded-full bg-blue-purple bg-opacity-50 filter blur-[80px]"></div> */}
          <div className="w-80 h-80 rounded-full bg-blue-purple bg-opacity-50 flex items-center justify-center">
            <GiStarsStack className="text-blue-purple text-30" />
          </div>
        </div>
      ),
    },
    {
      title: "Work Online",
      text: "Jouw website bezoekers, volgers en (zakelijke) netwerk zijn geld waard. Bij UNIVERSALES kun je geld verdienen per lead of per sale, maar nog belangrijker, door innovatieve tools en persoonlijke ondersteuning helpt UNIVERSALES je bij het maximaliseren van je inkomsten.",
      icon: (
        <div className="w-80 h-80 relative">
          {/* <div className="absolute top-0 left-0 w-80 h-80 rounded-full bg-purple-light bg-opacity-50 filter blur-[80px]"></div> */}
          <div className="w-80 h-80 rounded-full bg-purple-light bg-opacity-50 flex items-center justify-center">
            <BiWorld className="text-purple-light text-30" />
          </div>
        </div>
      ),
    },
  ])

  return (
    <>
      <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-40 xl:gap-50">
        {benefits.map((benefit: BenefitInterface, key: number) => (
          <div className="w-full flex justify-center" key={key}>
            <div className="w-[500px] max-w-full" key={key}>
              <div className="w-full flex justify-center mb-20 md:mb-32">{benefit.icon}</div>
              <div className="w-full text-center mb-12 md:mb-24 text-24 md:text-36 text-white font-bold">
                {benefit.title}
              </div>
              <div className="w-full flex justify-center">
                <div className="w-[340px] max-w-full text-left text-grey text-14 font-semibold">{benefit.text}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default HomeBenefits
