import axios from "axios"
import { REQ } from "libs/constants"
import React, { useState } from "react"
import { IoMdChatboxes } from "react-icons/io"
import { toast } from "react-toastify"
import Area from "utils/Area"
import Button from "utils/Button"
import Input from "utils/Input"

const AdvertisingCoffee = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {
    if (!(name && email && message)) {
      return toast.error("Vul het formulier volledig in!")
    }

    if (submitting) {
      return toast.error("Wacht met het versturen van je volgende aanmelding!")
    }

    submitForm()
  }

  const submitForm = async () => {
    toast.success("Je aanmelding wordt verwerkt!")

    if (!submitting) {
      setSubmitting(true)

      const data = new FormData()

      data.append("name", name)
      data.append("email", email)
      data.append("message", message)
      data.append("formType", "coffee")

      axios({
        method: "post",
        url: REQ.FORM,
        data: data,
      })
        .then(() => {
          // Reset fields
          setName("")
          setEmail("")
          setMessage("")

          setSubmitting(false)

          toast.success("Je aanmelding is successvol verstuurd!")
        })
        .catch(() => {
          toast.error("Er ging iets fout, probeer het opnieuw")
        })
    }
  }

  return (
    <>
      <div className="w-full rounded-16 bg-background overflow-hidden p-30 sm:p-40 xl:p-100 border-2 border-purple">
        {/* <div className="w-full relative">
          <div className="absolute top-0 left-0 bg-gradient-to-r from-blue-purple to-blue w-full h-full opacity-10"></div>
          <div className="absolute opacity-30 top-60 bottom-60 left-[140px] right-[140px] bg-gradient-to-r from-purple to-blue rounded-16 filter blur-[200px]"></div>
        </div> */}
        <div className="w-full grid grid-cols-1 xl:grid-cols-[1fr,500px] gap-40 xl:gap-100 items-center">
          <div className="w-full">
            <div className="w-full flex mb-24 md:mb-32">
              <div className="w-80 h-80 bg-white-10 rounded-full flex items-center justify-center">
                <IoMdChatboxes className="text-32 text-white" />
              </div>
            </div>
            <div className="w-full grid grid-col-1 gap-24">
              <div className="w-full font-bold text-white text-24 sm:text-32 md:text-48">Zet de koffie klaar</div>
              <div className="hidden w-full text-grey text-14 sm:text-18">
                Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel
                een hoge motivatie en doorzettingsvermogen. Start als Sales Agent bij een van de aangesloten
                verkooporganisaties en maak je eerste omzet! Hieronder lees je wat je kunt gaan doen en mag verwachten.
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full grid grid-cols-1 gap-24 mb-32">
              <Input title="Volledige naam" value={name} handler={setName} />
              <Input title="E-mail" value={email} handler={setEmail} />
              <Area title="Bericht" value={message} handler={setMessage} />
            </div>
            <div className="w-full flex">
              <div onClick={() => onSubmit()} className="w-[230px] max-w-full">
                <Button title={"Zet de koffie klaar"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvertisingCoffee
