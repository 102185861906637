import React from "react";

const ContentSubTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full text-grey-text text-14 md:text-16">{title}</div>
    </>
  );
};

export default ContentSubTitle;
