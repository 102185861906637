import React from "react";

const ContentSubTextBold = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full text-grey-text font-bold text-18">{title}</div>
    </>
  );
};

export default ContentSubTextBold;
