import { PrivacyListInterface } from "libs/interfaces"
import React from "react"

const PrivacyList = ({ list }: { list: PrivacyListInterface[] }) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-12">
        {list.map((item: PrivacyListInterface, key: number) => (
          <div className="w-full grid grid-cols-1 gap-12" key={key}>
            <div className="w-full grid grid-cols-[auto,1fr] gap-6">
              <div className={`w-20 pl-16 ${item.text ? "text-white text-16 font-semibold" : "text-white-60 text-14"}`}>
                {item.prefix ? item.prefix : "-"}
              </div>
              <div
                className={`w-full pl-16 ${item.text ? "text-white text-16 font-semibold" : "text-white-60 text-14"}`}
              >
                {item.title}
              </div>
            </div>
            {item.text ? <div className="w-full pl-30 text-white-60 text-14">{item.text}</div> : ""}
          </div>
        ))}
      </div>
    </>
  )
}

export default PrivacyList
