import React from "react";

const Input = ({ title, value, handler }: { title: string; value: string; handler: any }) => {
  return (
    <>
      <div className="w-full h-60 bg-grey-border bg-opacity-40 rounded-6 flex items-center px-20">
        <input
          type="text"
          className="w-full text-14 md:text-16 font-semibold placeholder-grey:placeholder text-white"
          placeholder={title}
          value={value}
          onChange={(e) => handler(e.target.value)}
        />
      </div>
    </>
  );
};

export default Input;
