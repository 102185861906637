import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ title, link }: { title: string; link: string }) => {
  return (
    <>
      <Link to={link} className="w-full text-grey hover:text-white text-14 xl:text-18">
        {title}
      </Link>
    </>
  );
};

export default FooterLink;
