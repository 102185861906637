import React from "react"

const PrivacyTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="text-18 text-white sm:text-24 font-bold">{title}</div>
    </>
  )
}

export default PrivacyTitle
