import React from "react";

const Button = ({ title }: { title: string }) => {
  return (
    <>
      <button className="relative w-full p-[2px] bg-gradient-to-r from-purple to-blue rounded-[30px] overflow-hidden">
        <div className="absolute top-0 left-0 h-60 w-full bg-gradient-to-b from-white to-transparent opacity-20"></div>
        <div className="relative h-[54px] flex justify-center items-center px-20 rounded-[30px] bg-gradient-to-r from-purple active:from-transparent to-blue active:to-transparent text-white text-16 font-bold">
          {title}
        </div>
      </button>
    </>
  );
};

export default Button;
