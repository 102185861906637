import ShadeTop from "assets/img/shade_top.png"
import AboutSteps from "components/about/AboutSteps"
import AboutTop from "components/about/AboutTop"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import React from "react"

const About = () => {
  return (
    <>
      <Nav />
      <div className="w-full relative">
        <div className="absolute top-[-465px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
      </div>
      <div className="w-full mt-80 flex items-center justify-center p-20 md:px-30 md:py-50 relative z-20">
        <div className="w-full max-w-screen-2xl">
          <div className="w-full my-0 2xl:mt-[60px] 2xl:mb-[60px]">
            <div className="w-full mb-40 md:mb-[140px]">
              <AboutTop />
            </div>
            <div className="w-full mb-40 md:mb-[140px]">
              <AboutSteps />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
