import { ReactComponent as Dotted } from "assets/img/dotted.svg"
import ShadeTop from "assets/img/shade_top.png"
import Triangle from "assets/img/triangle.png"
import { BenefitInterface } from "libs/interfaces"
import React, { useState } from "react"
import { FaUsers } from "react-icons/fa"
import { GiStarsStack } from "react-icons/gi"

const AdvertisingStart = () => {
  const [benefits] = useState([
    {
      title: "Vakprofessionals",
      text: "Een fulltimer met speciale vakkennis nodig in IT, Techniek of Finance? Perfect! Want bij UNIVERSALES leiden wij deze vakprofessionals op. Daarna gaan ze fulltime bij jou aan de slag.",
      icon: (
        <div className="w-80 h-80 relative">
          {/* <div className="absolute top-0 left-0 w-80 h-80 rounded-full bg-blue bg-opacity-50 filter blur-[80px]"></div> */}
          <div className="w-80 h-80 rounded-full bg-blue bg-opacity-50 flex items-center justify-center">
            <GiStarsStack className="text-blue text-30" />
          </div>
        </div>
      ),
    },
    {
      title: "Werving en selectie",
      text: "Wij zorgen dat jouw vacature wordt gezien en selecteren alleen de allerbeste kandidaten. Jij kiest, neemt de beste zelf in dienst en betaalt pas als hij of zij zijn proeftijd door is.",
      icon: (
        <div className="w-80 h-80 relative">
          {/* <div className="absolute top-0 left-0 w-80 h-80 rounded-full bg-purple bg-opacity-50 filter blur-[80px]"></div> */}
          <div className="w-80 h-80 rounded-full bg-purple bg-opacity-50 flex items-center justify-center">
            <FaUsers className="text-purple text-30" />
          </div>
        </div>
      ),
    },
  ])

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-40 md:gap-80 xl:gap-200 3xl:gap-80 relative">
        <div className="absolute top-[-600px] left-[50%] transform translate-x-[-50%] w-max">
          <img src={ShadeTop} alt="" className="" />
        </div>
        <div className="w-full flex flex-wrap justify-center 2xl:justify-start relative">
          <div className="absolute w-[1160px] transform top-[20%] translate-x-[50%] translate-y-[-50%] right-[-150px] rotate-[-10deg]">
            <img src={Triangle} alt="" className="w-full" />
          </div>
          <div className="w-full mb-40 md:mb-[110px] grid grid-cols-1 gap-12 md:gap-30">
            <div className="w-full flex justify-center">
              <div className="w-[580px] max-w-full text-white text-32 sm:text-48 md:text-64 font-bold leading-[40px] sm:leading-[50px] md:leading-[72px] text-center">
                Let&apos;s do business!
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <Dotted />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-40 xl:gap-50">
            {benefits.map((benefit: BenefitInterface, key: number) => (
              <div className="w-full flex justify-center" key={key}>
                <div className="w-[500px] max-w-full" key={key}>
                  <div className="w-full flex justify-center mb-20 md:mb-32">{benefit.icon}</div>
                  <div className="w-full text-center mb-12 md:mb-24 text-24 md:text-36 text-white font-bold">
                    {benefit.title}
                  </div>
                  <div className="w-full flex justify-center">
                    <div className="w-[360px] max-w-full text-left text-grey text-14 font-semibold">{benefit.text}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvertisingStart
