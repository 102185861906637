import Map from "assets/img/map.png"
import axios from "axios"
import { MAP, REQ } from "libs/constants"
import { openLink } from "libs/functions"
import React, { useState } from "react"
import { toast } from "react-toastify"
import Area from "utils/Area"
import Button from "utils/Button"
import Input from "utils/Input"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {
    if (!(name && email && phone && message)) {
      return toast.error("Vul het formulier volledig in!")
    }

    if (submitting) {
      return toast.error("Wacht met het versturen van je volgende aanmelding!")
    }

    submitForm()
  }

  const submitForm = async () => {
    toast.success("Je aanmelding wordt verwerkt!")

    if (!submitting) {
      setSubmitting(true)

      const data = new FormData()

      data.append("name", name)
      data.append("email", email)
      data.append("phone", phone)
      data.append("message", message)
      data.append("formType", "contact")

      axios({
        method: "post",
        url: REQ.FORM,
        data: data,
      })
        .then(() => {
          // Reset fields
          setName("")
          setEmail("")
          setPhone("")
          setMessage("")

          setSubmitting(false)

          toast.success("Je aanmelding is successvol verstuurd!")
        })
        .catch(() => {
          toast.error("Er ging iets fout, probeer het opnieuw")
        })
    }
  }

  return (
    <>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-40 3xl:gap-80 relative">
        <div className="w-full">
          <div className="w-full mb-24 text-24 md:text-36 xl:text-48 text-white font-bold text-center xl:text-left">
            Stuur ons een bericht
          </div>
          <div className="w-full flex justify-center">
            <div className="w-full mb-48 text-14 xl:text-18 text-grey font-semibold text-center xl:text-left">
              Hebt u een vraag? We staan voor u klaar. Stuur ons een bericht, dan nemen we contact met u op.
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-24 mb-32">
            <Input title="Naam" value={name} handler={setName} />
            <Input title="E-mail" value={email} handler={setEmail} />
            <Input title="Telefoonnummer" value={phone} handler={setPhone} />
            <Area title="Bericht" value={message} handler={setMessage} />
          </div>
          <div className="w-full flex justify-start">
            <div onClick={() => onSubmit()} className="w-[210px]">
              <Button title={"Indienen"} />
            </div>
          </div>
        </div>
        <div className="w-full relative flex justify-center min-h-[400px]">
          <button
            onClick={(e) => openLink(e, MAP.ANKERSMITPLEIN)}
            className="w-full xl:w-[50vw] min-h-[400px] overflow-hidden relative xl:absolute top-0 bottom-0 left-0 bg-white rounded-16"
          >
            <img
              src={Map}
              alt=""
              className="relative xl:absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] min-h-full min-w-max"
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default ContactForm
