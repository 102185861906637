import React from "react"

const PrivacyText = ({ text }: { text: JSX.Element }) => {
  return (
    <>
      <div className="text-14 text-white-60">{text}</div>
    </>
  )
}

export default PrivacyText
