import axios from "axios"
import { REQ } from "libs/constants"
import { StepsInterface } from "libs/interfaces"
import React, { useState } from "react"
import { FaEdit } from "react-icons/fa"
import { IoMdRocket } from "react-icons/io"
import { MdSmartphone } from "react-icons/md"
import { toast } from "react-toastify"
import Button from "utils/Button"
import CheckList from "utils/CheckList"
import Dropzone from "utils/Dropzone"
import Input from "utils/Input"

const HomeRegister = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [contact, setContact] = useState("")
  const [preference, setPreference] = useState("")
  const [availability, setAvailability] = useState("")

  const [submitting, setSubmitting] = useState(false)

  const [files, setFiles] = useState<File[]>()

  const [steps] = useState<StepsInterface[]>([
    {
      icon: (
        <div className="bg-blue-10 h-60 w-60 rounded-full flex items-center justify-center">
          <FaEdit className="text-blue text-24" />
        </div>
      ),
      title: "1. Maak je UNIVERSALES-profiel",
      text: "Vertel opdrachtgevers meer over jezelf en wat je te bieden hebt.",
    },
    {
      icon: (
        <div className="bg-blue-purple bg-opacity-10 h-60 w-60 rounded-full flex items-center justify-center">
          <MdSmartphone className="text-blue-purple text-24" />
        </div>
      ),
      title: "2. Persoonlijk contact",
      text: "Laat jouw vaste contactpersoon weten wat jij belangrijk vindt.",
    },
    {
      icon: (
        <div className="bg-purple-10 h-60 w-60 rounded-full flex items-center justify-center">
          <IoMdRocket className="text-purple text-24 transform rotate-45" />
        </div>
      ),
      title: "3. Start jouw Sales carrière!",
      text: "Er is een match tussen jou en de opdrachtgever.",
    },
  ])

  const onSubmit = () => {
    if (!(name && phone && email && contact && preference && availability)) {
      return toast.error("Vul het formulier volledig in!")
    }

    if (submitting) {
      return toast.error("Wacht met het versturen van je volgende aanmelding!")
    }

    submitForm()
  }

  const submitForm = async () => {
    toast.success("Je aanmelding wordt verwerkt!")

    if (!submitting) {
      setSubmitting(true)

      const data = new FormData()

      data.append("name", name)
      data.append("phone", phone)
      data.append("email", email)
      data.append("contact", contact)
      data.append("preference", preference)
      data.append("availability", availability)
      data.append("formType", "register")

      if (files) {
        data.append("file", files[0], files[0].name)
      }

      axios({
        method: "post",
        url: REQ.FORM,
        data: data,
      })
        .then(() => {
          // Reset fields
          setName("")
          setPhone("")
          setEmail("")
          setContact("")
          setPreference("")
          setAvailability("")

          setSubmitting(false)

          toast.success("Je aanmelding is successvol verstuurd!")
        })
        .catch(() => {
          toast.error("Er ging iets fout, probeer het opnieuw")
        })
    }
  }

  return (
    <>
      <div className="w-full">
        {/* <div className="w-full relative">
          <div className="absolute w-[1160px] z-0 transform top-[100px] translate-x-[50%] translate-y-[-50%] right-[-180px] rotate-[10deg]">
            <img src={Triangle} alt="" className="w-full" />
          </div>
        </div> */}
        <div className="w-full grid grid-cols-1 xl:grid-cols-[1fr,auto,1fr] gap-50 z-20">
          <div className="w-full">
            <div className="flex justify-center md:justify-start w-full mb-30 md:mb-48 text-white font-bold text-36 sm:text-48 md:text-64 flex-wrap gap-4 leading-[40px] sm:leading-[50px] md:leading-[70px]">
              <span className="text-36 sm:text-48 md:text-64 text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
                UNIVERSALES
              </span>
              PLATFORM
            </div>
            <div className="text-center md:text-left w-full text-grey text-18 md:text-24 mb-48">
              <span className="text-18 md:text-24 text-white font-semibold">Binnen 48 uur</span> gegarandeerd reactie op
              je aanmelding en een stap dichter bij de start van jouw Sales carrière!
            </div>
            <div className="w-full grid grid-cols-1 gap-20 md:gap-48">
              {steps.map((step: StepsInterface, key: number) => (
                <div className="w-full grid grid-cols-1 md:grid-cols-[auto,1fr] gap-24" key={key}>
                  <div className="w-full md:w-auto flex justify-center">{step.icon}</div>
                  <div className="w-full grid grid-cols-1 gap-10">
                    <div className="w-full text-white text-18 md:text-24 font-bold text-center md:text-left">
                      {step.title}
                    </div>
                    <div className="w-full text-grey text-14 md:text-18 text-center md:text-left">{step.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full xl:w-auto border-b-1 xl:border-b-0 xl:border-r-1 border-grey-border"></div>
          <div className="w-full">
            <div className="w-full relative">
              <div id="register" className="w-full absolute top-0 left-0"></div>
            </div>
            <div className="w-full mb-20 text-24 md:text-36 font-bold text-white">MELD JE HIER AAN</div>
            <div className="w-full grid grid-cols-1 gap-24 mb-32">
              <Input title={"Volledige naam"} value={name} handler={setName} />
              <Input title={"Telefoonnummer"} value={phone} handler={setPhone} />
              <Input title={"E-mail"} value={email} handler={setEmail} />
              <Input title={"Contact persoon"} value={contact} handler={setContact} />
              <div className="w-full grid grid-cols-1 md:grid-cols-[auto,1fr] gap-16 md:gap-24">
                {/* <div className="text-white text-16 md:text-18 font-semibold flex items-center">Contact</div>
                <Dropdown title="Geen" options={["Geen", "Nauwelijks", "Ervaren"]} value={experience} handler={setExperience} /> */}
                <div className="text-white text-18 font-semibold">Mijn voorkeur</div>
                <CheckList
                  options={["Sales Agent", "Recruiter", "Horeca", "Buitenland", "Vind werk voor mij"]}
                  value={preference}
                  handler={setPreference}
                />
                <div className="text-white text-18 font-semibold">Beschikbaarheid</div>
                <CheckList
                  options={["Fulltime ( 40 uur )", "Parttime ( 24 uur )"]}
                  value={availability}
                  handler={setAvailability}
                />
              </div>
              <Dropzone files={files} handler={setFiles} />
            </div>
            <div className="w-full flex">
              <div onClick={() => onSubmit()} className="w-[200px] max-w-full">
                <Button title={"Aanmelden"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeRegister
