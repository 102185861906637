import Marketing from "assets/img/campaigns/marketing.png"
import Smartphones from "assets/img/campaigns/smartphones.png"
import Vodaphone from "assets/img/campaigns/vodaphone.jpg"
import Ziggo from "assets/img/campaigns/ziggo.jpg"
import { CampaignInterface } from "libs/interfaces"
import React, { useState } from "react"
import { Link as LinkScroll } from "react-scroll"
import Button from "utils/Button"
const HomeCampaigns = () => {
  const [campaigns] = useState<CampaignInterface[]>([
    {
      icon: Vodaphone,
      title: "VODAFONE",
      text: (
        <>
          Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een
          hoge motivatie en doorzettingsvermogen. <br />
          Begin als Sales Agent bij één van de aangesloten verkooporganisaties en start met het maken van sales.
        </>
      ),
    },
    {
      icon: Ziggo,
      title: "ZIGGO",
      text: (
        <>
          Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een
          hoge motivatie en doorzettingsvermogen. <br />
          Begin als Sales Agent bij één van de aangesloten verkooporganisaties en start met het maken van sales.
        </>
      ),
    },
    {
      icon: Smartphones,
      title: "SMARTPHONES",
      text: (
        <>
          Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een
          hoge motivatie en doorzettingsvermogen. <br />
          Begin als Sales Agent bij één van de aangesloten verkooporganisaties en start met het maken van sales.
        </>
      ),
    },
    {
      icon: Marketing,
      title: "MEDIA",
      text: (
        <>
          Topverkoper wordt je niet zomaar, je moet het ergens leren toch? Daarom is ervaring geen vereiste. Wel een
          hoge motivatie en doorzettingsvermogen. <br />
          Begin als Sales Agent bij één van de aangesloten verkooporganisaties en start met het maken van sales.
        </>
      ),
    },
  ])

  return (
    <>
      {/* <div className="w-full relative hidden sm:flex">
        <div className="absolute w-[1160px] transform top-[50%] translate-x-[50%] translate-y-[-50%] right-[-250px] rotate-[-10deg]">
          <img src={Triangle} alt="" className="w-full" />
        </div>
      </div> */}
      <div className="w-full">
        <div className="w-full mb-40 xl:mb-[140px] text-white font-bold text-64 flex flex-wrap gap-4 leading-[40px] sm:leading-[50px] md:leading-[70px]">
          {/* <div className="w-full relative">
            <div className="absolute w-[900px] h-[900px] transform bg-gradient-to-b from-purple to-transparent top-[50%] translate-x-[-50%] translate-y-[-50%] rotate-90 left-[-530px] filter blur-[350px]"></div>
            <div className="absolute w-[430px] h-[430px] rounded-30 transform bg-gradient-to-r from-purple to-blue top-[50%] translate-x-[-50%] translate-y-[-50%] rotate-45 left-[-360px]"></div>
          </div> */}
          <span className="w-full text-center text-36 sm:text-48 md:text-64 text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
            UNIVERSALES
          </span>
          <span className="w-full text-center text-36 sm:text-48 md:text-64">CAMPAGNES</span>
        </div>
        <div className="w-full flex justify-center mb-40 md:mb-120">
          <div className="w-[1000px] max-w-full grid grid-cols-1 gap-40 md:gap-80 xl:gap-[130px]">
            {campaigns.map((campaign: CampaignInterface, key: number) => (
              <div
                className={`w-full flex flex-wrap xl:grid md:gap-50 xl:gap-100 items-center grid-cols-1 ${
                  key % 2 === 0 ? "xl:grid-cols-[500px,1fr]" : "xl:grid-cols-[1fr,500px] flex-col-reverse"
                }`}
                key={key}
              >
                {key % 2 === 0 ? (
                  <>
                    <div className="w-full flex justify-center mb-30 md:mb-0">
                      <div className="w-[500px] max-w-full relative">
                        <div className="w-full h-full absolute left-[-10px] xl:left-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                        <img src={campaign.icon} alt="" className="relative w-full rounded-16" />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="w-full text-24 md:text-36 xl:text-48 text-white font-bold text-center xl:text-right">
                        {campaign.title}
                      </div>
                      <div className="hidden w-full justify-center">
                        <div className="w-[600px] max-w-full text-14 xl:text-18 text-grey font-semibold text-center xl:text-left">
                          {campaign.text}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full">
                      <div className="w-full text-24 md:text-36 xl:text-48 text-white font-bold text-center xl:text-left">
                        {campaign.title}
                      </div>
                      <div className="hidden w-full justify-center">
                        <div className="w-[600px] max-w-full text-14 xl:text-18 text-grey font-semibold text-center xl:text-left">
                          {campaign.text}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-center mb-30 md:mb-0">
                      <div className="w-[500px] max-w-full relative">
                        <div className="w-full h-full absolute -left-10 xl:-left-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                        <img src={campaign.icon} alt="" className="relative w-full rounded-16" />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <LinkScroll to={"register"} offset={-100}>
            <div className="w-[210px]">
              <Button title={"Meld je aan"} />
            </div>
          </LinkScroll>
        </div>
      </div>
    </>
  )
}

export default HomeCampaigns
