import axios from "axios"
import { REQ, URL } from "libs/constants"
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import ScrollToTop from "utils/ScrollToTop"
import About from "views/About"
import Advertising from "views/Advertising"
import Contact from "views/Contact"
import Content from "views/Content"
import Evolution from "views/Evolution"
import Home from "views/Home"
import NotFound from "views/NotFound"
import Privacy from "views/Privacy"

const App = () => {
  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = () => {
    axios({
      method: "get",
      url: REQ.HEALTH,
      data: {},
    })
      .then((res: any) => res.data)
      .then((data: any) => console.log(data.status))
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <>
      <div className="app w-full min-w-[300px]">
        <div className="fixed z-[-1] top-0 left-0 bottom-0 right-0 bg-background"></div>
        <div className="w-full overflow-hidden">
          <Router basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Switch>
              <Route exact path={URL.HOME}>
                <Home />
              </Route>
              <Route exact path={URL.ABOUT}>
                <About />
              </Route>
              <Route exact path={URL.ADVERTISING}>
                <Advertising />
              </Route>
              <Route exact path={URL.CONTACT}>
                <Contact />
              </Route>
              <Route exact path={URL.CONTENT}>
                <Content />
              </Route>
              <Route exact path={URL.EVOLUTION}>
                <Evolution />
              </Route>
              <Route exact path={URL.PRIVACY}>
                <Privacy />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </>
  )
}

export default App
