import Create from "assets/img/about/create.png"
import { ReactComponent as Curve } from "assets/img/about/curve.svg"
import Learn from "assets/img/about/learn.png"
import Practice from "assets/img/about/practise.png"
import { ReactComponent as Dotted } from "assets/img/dotted.svg"
import Triangle from "assets/img/triangle.png"
import { URL } from "libs/constants"
import { CampaignInterface } from "libs/interfaces"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import Button from "utils/Button"

const AboutSteps = () => {
  const [steps] = useState<CampaignInterface[]>([
    {
      icon: Learn,
      title: "Learn",
      text: (
        <>
          Talent, Toewijding en Motivatie spelen een belangrijke rol bij het behalen van succes in de Sales. UNIVERSALES
          ondersteund jou bij het verder ontwikkelen van je verkooptalent met ons unieke Traineeship programma.
        </>
      ),
    },
    {
      icon: Practice,
      title: "Practice",
      text: (
        <>
          De kennis die je hebt opgedaan tijdens je opleiding of ons Traineeship programma wil je natuurlijk in de
          praktijk brengen. Via het UNIVERSALES platform kun je aan de slag bij de beste verkoop organisaties.
        </>
      ),
    },
    {
      icon: Create,
      title: "Create",
      text: (
        <>
          Via het UNIVERSALES platform leer je de vaardigheden die nodig zijn voor een succesvolle carrière in sales.
          Wij zorgen ervoor dat je aan de slag kunt bij de beste verkoop organisaties en helpen jou op weg naar
          financiële en persoonlijke vrijheid.
        </>
      ),
    },
  ])

  return (
    <>
      <div className="w-full">
        <div className="w-full mb-40 md:mb-[110px]">
          <div className="w-full relative">
            <div className="absolute w-[1160px] transform top-[50%] translate-x-[50%] translate-y-[-50%] right-[-250px] rotate-[-10deg]">
              <img src={Triangle} alt="" className="w-full" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12 md:gap-30">
            <div className="w-full flex justify-center">
              <div className="w-[580px] max-w-full text-white text-32 sm:text-48 md:text-64 font-bold leading-[40px] sm:leading-[50px] md:leading-[72px] text-center">
                Start right now
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <Dotted />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-center">
          {/* <div className="w-full relative">
            <div className="absolute w-[900px] h-[900px] transform bg-gradient-to-b from-purple to-transparent top-[50%] translate-x-[-50%] translate-y-[-50%] rotate-90 left-[-530px] filter blur-[350px]"></div>
            <div className="absolute w-[430px] h-[430px] rounded-30 transform bg-gradient-to-r from-purple to-blue top-[50%] translate-x-[-50%] translate-y-[-50%] rotate-45 left-[-360px]"></div>
          </div> */}
          <div className="w-[1200px] max-w-full grid grid-cols-1 gap-40 md:gap-60">
            {steps.map((step: CampaignInterface, key: number) => (
              <div className="w-full grid grid-cols-1 gap-40 md:gap-60" key={key}>
                <div
                  className={`w-full flex flex-wrap xl:grid gap-30 md:gap-50 xl:gap-100 items-center grid-cols-1 ${
                    key % 2 !== 0 ? "xl:grid-cols-[500px,1fr]" : "xl:grid-cols-[1fr,500px] flex-col-reverse"
                  }`}
                  key={key}
                >
                  {key % 2 !== 0 ? (
                    <>
                      <div className="w-full flex justify-center">
                        <div className="w-[500px] max-w-full relative">
                          <div className="w-full h-full absolute -left-10 xl:left-30 xl:-right-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                          <img src={step.icon} alt="" className="relative w-full rounded-16" />
                          <div className="absolute w-full h-full top-0 left-0 bg-blue-purple bg-opacity-25 rounded-16"></div>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="w-full text-center xl:text-left mb-18 sm:mb-24 text-18 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
                          Step {key + 1}
                        </div>
                        <div className="w-full mb-24 text-24 md:text-36 xl:text-48 text-white font-bold text-center xl:text-left">
                          {step.title}
                        </div>
                        <div className="w-full flex justify-center">
                          <div className="w-[600px] max-w-full mb-48 text-14 xl:text-18 text-grey font-semibold text-center xl:text-left">
                            {step.text}
                          </div>
                        </div>
                        <div className="w-full flex justify-center xl:justify-start">
                          <Link to={URL.REGISTER} className="w-[210px]">
                            <Button title={"Meld je aan"} />
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full">
                        <div className="w-full text-center xl:text-left mb-18 sm:mb-24 text-18 font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple to-blue">
                          Step {key + 1}
                        </div>
                        <div className="w-full mb-24 text-24 md:text-36 xl:text-48 text-white font-bold text-center xl:text-left">
                          {step.title}
                        </div>
                        <div className="w-full flex justify-center">
                          <div className="w-[600px] max-w-full mb-48 text-14 xl:text-18 text-grey font-semibold text-center xl:text-left">
                            {step.text}
                          </div>
                        </div>
                        <div className="w-full flex justify-center xl:justify-start">
                          <Link to={URL.REGISTER} className="w-[210px]">
                            <Button title={"Meld je aan"} />
                          </Link>
                        </div>
                      </div>
                      <div className="w-full flex justify-center">
                        <div className="w-[500px] max-w-full relative">
                          <div className="w-full h-full absolute -left-10 xl:-left-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                          <div className="relative overflow-hidden rounded-16">
                            <img src={step.icon} alt="" className="relative w-full rounded-16" />
                            <div className="absolute w-full h-full top-0 left-0 rounded-16 bg-blue-purple bg-opacity-25"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {key < steps.length - 1 ? (
                  <div
                    className={`hidden xl:flex w-full items-center justify-center transform ${
                      key === 1 ? "scale-x-[-1]" : ""
                    }`}
                  >
                    <Curve className="h-[300px]" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutSteps
