import Madrid from "assets/img/evolution/madrid.png"
import Malta from "assets/img/evolution/malta.png"
import Triangle from "assets/img/triangle.png"
import ContentSubText from "components/content/ContentSubText"
import { URL } from "libs/constants"
import { CareerInterface } from "libs/interfaces"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import Button from "utils/Button"
import Wrapper from "utils/Wrapper"

const EvolutionAbout = () => {
  const [about] = useState<CareerInterface[]>([
    {
      icon: Malta,
      title: "About Malta",
      content: (
        <div className="w-full grid grid-cols-1 gap-20">
          <ContentSubText title="Malta, een klein maar levendig eiland in het hart van de Middellandse Zee. Het eiland biedt een veelzijdigheid aan schilderachtige gebieden, erfgoed, prachtige stranden en het commerciële leven. Een rit van de verste punten op het eiland duurt maximaal 60 minuten, en realistisch gezien kun je nooit meer dan 20 minuten van het strand verwijderd zijn. Een bikini is dus altijd handig. Met haar warme klimaat, mediterrane levensstijl en mensen die bekend staan om hun gastvrijheid, is Malta een thuis voor veel expats." />
        </div>
      ),
    },
    {
      icon: Madrid,
      title: "About Madrid",
      content: (
        <div className="w-full grid grid-cols-1 gap-20">
          <ContentSubText title="De hoofdstad van Spanje kan soms worden gezien als de hoofdstad van de cultuur: met gigantische musea, zonnige pleinen, heerlijke tapas en een bruisend nachtleven. Je vervelen is niet mogelijk maar als dit toch gebeurt, is een uitstapje naar een ander deel van het land erg eenvoudig met het openbaar vervoer. Sommigen komen hier voor een citytrip en willen niet meer weg, maar nu heb je dus echt de kans om hier te blijven!" />
        </div>
      ),
    },
  ])

  return (
    <>
      <div className="w-full relative">
        <div className="absolute w-[1160px] transform translate-x-[50%] translate-y-[-50%] right-[-150px] rotate-[-0deg] top-60">
          <img src={Triangle} alt="" className="w-full" />
        </div>

        <div className="w-full grid grid-cols-1 gap-100">
          {about.map((step: CareerInterface, key: number) => (
            <div className="w-full" key={key}>
              {key % 2 === 0 ? (
                <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-40 md:gap-100 items-center" key={key}>
                  <div className="w-full">
                    <div className="w-full flex justify-center">
                      <div className="w-[700px] max-w-full relative xl:my-60">
                        <div className="w-full h-full absolute -left-10 xl:-left-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                        <div className="relative rounded-16 overflow-hidden">
                          <img src={step.icon} alt="" className="w-full object-cover" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-center">
                    <div className="w-[700px] max-w-full grid grid-cols-1 gap-32">
                      <div className="w-full text-white text-36 sm:text-48 font-bold">{step.title}</div>
                      <div className="w-full">{step.content}</div>
                      <div className="w-full flex">
                        <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                          <Button title={"Meld je aan"} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-full flex flex-col-reverse xl:flex-col xl:grid xl:grid-cols-2 gap-100 items-center"
                  key={key}
                >
                  <div className="w-full flex justify-center">
                    <div className="w-[700px] max-w-full grid grid-cols-1 gap-20 md:gap-32">
                      <div className="w-full text-white text-36 sm:text-48 font-bold">{step.title}</div>
                      <div className="w-full">{step.content}</div>
                      <div className="w-full flex">
                        <Link to={URL.REGISTER} className="w-full md:w-[212px]">
                          <Button title={"Meld je aan"} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-center">
                    <div className="w-[700px] max-w-full relative xl:my-60">
                      <div className="w-full h-full absolute -left-10 xl:left-30 xl:-right-30 -bottom-10 xl:-bottom-30 rounded-16 bg-gradient-to-r from-purple to-blue"></div>
                      <div className="relative flex justify-center overflow-hidden rounded-16">
                        <img src={step.icon} alt="" />
                        <Wrapper open={key === 1}>
                          <div className="absolute w-full h-full top-0 left-0 bg-blue-purple bg-opacity-25"></div>
                        </Wrapper>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default EvolutionAbout
