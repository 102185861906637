import React from "react"

const PrivacyItem = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full pl-16 text-white text-16 font-semibold">- {title}</div>
    </>
  )
}

export default PrivacyItem
