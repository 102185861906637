import React from "react";

const ContentSubTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full text-white text-18 md:text-24 font-semibold">{title}</div>
    </>
  );
};

export default ContentSubTitle;
